import HeaderWithBackButton from "components/HeaderWithBackButton";
import React from "react";

const PrivatePayment = () => {
  return (
    <>
      <HeaderWithBackButton title={"유료서비스 이용약관"} />
      <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
        <h1 className="text-2xl font-bold mb-4 text-center">캐스팅보트 유료서비스 이용약관</h1>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">제1조 (목적)</h2>
          <p className="text-gray-700">
            이 약관은 주식회사 커넥션스튜디오(이하 ‘회사’)가 운영 중인 CastingVote 서비스(이하 ‘서비스’)에서 제공되는 각종 유료 서비스의 이용조건 및 절차에 관한 사항을 규정함을 목적으로 합니다.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">제2조 (약관의 게시와 개정)</h2>
          <p className="text-gray-700">
            ① 회사는 이 약관을 회원이 그 전부를 인쇄 또는 확인할 수 있도록 기술적 조치를 취합니다.
          </p>
          <p className="text-gray-700">
            ② 회사는 회원이 약관에 동의하기에 앞서 약관에 정해져 있는 내용 중 청약철회 등과 같은 중요한 내용을 회원이 쉽게 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공합니다.
          </p>
          <p className="text-gray-700">
            ③ 회사는 ‘콘텐츠산업진흥법’, ‘전자상거래등에서의소비자보호에관한법률’, ‘약관의규제에관한법률’ 등 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
          </p>
          <p className="text-gray-700">
            ④ 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 서비스 초기 화면에 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리한 약관의 개정의 경우에는 30일 전부터 적용일자 전일까지 공지하며, 공지 외에 일정기간 서비스 내 전자우편, 전자쪽지, 로그인시 동의창 등의 전자적 수단을 통해 따로 명확히 통지하도록 합니다.
          </p>
          <p className="text-gray-700">
            ⑤ 회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 전항의 공지기간 내에 의사표시를 하지 않으면 개정약관의 적용에 동의한 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.
          </p>
          <p className="text-gray-700">
            ⑥ 회원이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">제3조 (약관의 해석)</h2>
          <p className="text-gray-700">
            이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 ‘콘텐츠진흥법’, ‘전자상거래등에서의소비자보호에관한법률’, ‘약관의규제에관한법률’, 문화체육관광부장관이 정하는 ‘콘텐츠이용자보호지침’, 기타 관계법령, ‘캐스팅 보트 이용약관’ 또는 상관례에 따릅니다.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">제4조 (회원에 대한 통지)</h2>
          <p className="text-gray-700">
            ① 회사가 회원에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 서비스 내 전자우편주소, 전자쪽지 등으로 할 수 있습니다.
          </p>
          <p className="text-gray-700">
            ② 회사는 회원 전체에 대한 통지의 경우 7일 이상 회사의 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 제1항의 통지를 합니다.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">제5조 (유료서비스의 내용 등의 게시)</h2>
          <p className="text-gray-700">
            ① 회사는 다음 사항을 해당 유료서비스의 이용 초기화면이나 FAQ 등에 회원이 알기 쉽게 표시합니다.
          </p>
          <ul className="list-disc pl-6 text-gray-700">
            <li>유료서비스의 명칭, 종류 및 내용</li>
            <li>유료서비스의 가격과 그 지급방법 및 시기</li>
            <li>유료서비스의 공급방법, 시기 및 제공기간</li>
          </ul>
          <p className="text-gray-700">
            ② 회사의 유료서비스의 이용 가능 기기 및 이용에 필요한 최소한의 기술사양은 권장사양정보에 따릅니다.
          </p>
          <p className="text-gray-700">
            ③ 회사는 유료서비스를 제공함에 있어 유료 서비스의 교환·반품·보증과 그 대금 환불의 조건 및 절차에 관한 사항을 제공합니다.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">제6조 (이용계약의 성립 등)</h2>
          <p className="text-gray-700">
            회원은 회사가 제공하는 다음 또는 이와 유사한 절차에 의하여 이용신청을 합니다. 회사는 계약 체결 전에 각 호의 사항에 관하여 회원이 정확하게 이해하고 실수 또는 착오 없이 거래할 수 있도록 정보를 제공합니다.
          </p>
          <ul className="list-disc pl-6 text-gray-700">
            <li>유료서비스의 확인 및 선택</li>
            <li>결제방법의 선택 및 결제정보의 입력</li>
            <li>유료서비스의 이용신청에 관한 확인 또는 회사의 확인에 대한 동의</li>
          </ul>
          <p className="text-gray-700">
            ② 회사는 회원의 이용신청이 다음 각 호에 해당하는 경우에는 승낙하지 않거나 승낙을 유보할 수 있습니다.
          </p>
          <ul className="list-disc pl-6 text-gray-700">
            <li>실명이 아니거나 타인의 명의를 이용한 경우</li>
            <li>허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우</li>
            <li>미성년자가 ‘청소년보호법’ 등 관련 법령에 의해서 이용이 금지되는 유료서비스를 이용하고자 하는 경우</li>
            <li>서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우 </li>
          </ul>
          <p className="text-gray-700">
            ③ 이용계약의 성립시기는 ‘가입완료’ 또는 ‘구매완료’를 신청절차 상에서 표시한 시점으로 합니다.
          </p>
          <p className="text-gray-700">
            ④ 회원이 유료서비스를 이용하기 위해서는 이 약관에 동의 후 각 서비스에 따른 이용조건에 따라 이용요금을 지급하여야 합니다.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">제7조 (유료서비스의 결제 등)</h2>
          <p className="text-gray-700">
            ① 회사가 제공하는 유료서비스를 이용하는 경우 회원은 이용대금을 납부하여야 하며, 유료서비스에 대한 이용요금 결제방법은 아래 각 호 중 하나와 같습니다.
          </p>
          <ul className="list-disc pl-6 text-gray-700">
            <li>선불카드, 직불카드, 신용카드 등 각종 카드결제</li>
            <li>카카오페이, 네이버페이, 엘페이, 페이코, 토스페이 등 각종 간편결제</li>
          </ul>
          <p className="text-gray-700">
            ② 회사는 회원이 결제수단에 대한 정당한 사용권한을 가지고 있는지의 여부를 확인할 수 있으며, 이에 대한 확인이 완료될 때까지 거래 진행을 중지하거나, 확인이 불가능한 경우 거래를 취소할 수 있습니다.
          </p>
          <p className="text-gray-700">
            ③ 회사의 정책 및 제1항의 결제업체(카드사, 이동통신사 등) 및 결제대행업체의 기준에 따라 회원 당월 누적 결제액 및 충전한도가 제한될 수 있습니다. 해당 기준을 초과한 경우 유료서비스의 추가 이용이 불가능할 수 있습니다.
          </p>
          <p className="text-gray-700">
            ④ 회원이 대금의 지급이나 결제를 위하여 입력한 정보에 대한 책임은 회원에게 있습니다.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">제8조 (유료서비스의 중단 및 변경)</h2>
          <p className="text-gray-700">
            ① 회사는 사업 종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 유료서비스를 제공할 수 없게 되는 경우, 회사는 이 약관에서 정한 방법으로 회원에게 통지하고 당초 회사에서 제시한 조건 또는 ‘콘텐츠이용자보호지침’ 및 관련 법령 규정에서 정한 바에 따라 회원에게 보상합니다.
          </p>
          <p className="text-gray-700">
            ② 회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부의 유료서비스를 변경할 수 있고, 변경 전 해당 서비스 초기 화면에 관련 사항을 게시합니다. 다만, 변경된 내용이 중대하거나 회원에게 불리한 경우에는 이 약관에서 정한 방법으로 통지하고, 중대하거나 회원에게 불리한 변경 내용에 동의하지 않는 회원은 제9조에서 정한 바에 따라 유료서비스 이용계약을 해지할 수 있습니다.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">제9조 (회원의 청약철회 및 계약해지)</h2>
          <p className="text-gray-700">
            ① 회사와 유료서비스 이용계약을 체결한 회원은 관련 법령에서 정한 바에 따라 일정 기간 내에 청약을 철회할 수 있습니다. 단, 유료서비스에는 ‘콘텐츠산업진흥법’, ‘전자상거래등에서의소비자보호에관한법률’ 등에서 규정하는 청약철회가 불가능한 서비스가 포함되어 있는 경우 회사는 청약철회권 제한을 위해 관련 법령상 필요한 조치를 취합니다.
          </p>
          <p className="text-gray-700">
            ② 회원은 다음 각 호의 사유가 있을 때 유료서비스 이용계약을 해지 또는 해제할 수 있습니다.
          </p>
          <ul className="list-disc pl-6 text-gray-700">
            <li>유료서비스에 장애·결함·하자 등이 발생하여 유료서비스가 정상적으로 제공되지 아니하고, 회사가 이를 1주일 내에 보완, 수정할 수 없는 경우 </li>
            <li>유료서비스 회원이 이 약관의 개정 또는 제8조 제2항 단서에 따른 서비스 변경에 동의하지 않아 회원탈퇴 또는 유료서비스 이용계약을 해지하는 경우 </li>
          </ul>
          <p className="text-gray-700">
            ③ 회원이 전항에 따라 유료서비스 이용계약을 해지 또는 해제하는 경우 회사는 회원으로부터 지급받은 대금을 당초 회사에서 정한 조건 또는 ‘콘텐츠이용자보호지침’ 및 관련 법령 규정, 이 약관에서 정한 바에 따라 회원에게 환불합니다.
          </p>
          <p className="text-gray-700">
            ④ 회원은 제2항과 무관하게 임의로 유료서비스 이용계약을 해지할 수 있습니다.
          </p>
          <ul className="list-disc pl-6 text-gray-700">
            <li>멤버십은 환불금이 없으며 다음 결제일 전일까지 이용 가능하고 더 이상 자동 결제가 되지 않습니다. 다만, 결제일부터 7일 내, 이용을 전혀 하지 않은 경우 회사 고객센터를 통해 100% 환불이 가능합니다.</li>
            <li>포인트의 경우 해지일 현재 잔여 이용요금의 10%(PG사 수수료 등)를 공제 후 회사가 별도로 정하는 방법으로 환불합니다.</li>
          </ul>
          <p className="text-gray-700">
            ⑤ 회사는 환불시 이용대금의 결제수단과 동일한 방법으로 환불하는 것을 원칙으로 합니다. 다만, 동일한 결제수단으로 환불이 불가능한 경우 회사가 개별서비스에서 정하는 별도의 방법으로 환불합니다.
          </p>
          <p className="text-gray-700">
            ⑥ 회사는 환불 의무가 발생한 날부터 3영업일 내에 환불절차를 진행합니다. 다만, 환불을 위하여 회원의 협조가 필요한 경우 또는 회원의 귀책사유로 환불이 지연된 경우에는 해당 일수만큼 환불이 지연될 수 있으며 지연이자는 발생하지 않습니다.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">제10조 (회사의 계약해제, 해지 및 이용제한)</h2>
          <p className="text-gray-700">
            ① 회사는 회원이 캐스팅보트 이용약관에서 정한 금지행위를 하였을 경우 해당 조항에 따라 사전통지 없이 계약을 해제, 해지하거나 또는 기간을 정하여 서비스이용을 제한할 수 있습니다.
          </p>
          <p className="text-gray-700">
            ② 제1항의 해제, 해지는 회사가 정한 통지방법에 따라 회원에게 그 의사를 표시한 때에 효력이 발생합니다.
          </p>
          <p className="text-gray-700">
            ③ 제1항의 사유로 유료서비스 이용계약을 해지하는 경우 별도 환불금은 없습니다.
          </p>
          <p className="text-gray-700">
            ④ 회사의 해제, 해지 및 이용제한에 대하여 회원은 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 회사가 인정하는 경우, 회사는 즉시 서비스의 이용을 재개합니다.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">제11조 (사용기간 등)</h2>
          <p className="text-gray-700">
            유료서비스 중 멤버십의 사용기간은 사전에 별도로 표시되지 않는 한 구매일로부터 다음 결제일까지이며, 포인트 충전(상시결제)의 사용기간은 구매일로부터 5년입니다. 해당 기간이 경과한 유료서비스는 이용권이 소멸되며 보유목록에서 삭제됩니다.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">제12조 (유료서비스 하자 등에 의한 회원피해보상)</h2>
          <p className="text-gray-700">
            회사는 유료서비스의 하자 등에 의한 회원의 피해보상 기준, 범위, 방법 및 절차에 관한 사항을 ‘콘텐츠이용자보호지침’에 따라 처리합니다.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">제13조 (책임 제한)</h2>
          <p className="text-gray-700">
            ① 회사는 관계법령의 변경, 천재지변 또는 이에 준하는 불가항력으로 인하여 유료서비스를 제공할 수 없는 경우에는 유료서비스 제공에 관한 책임이 면제됩니다.
          </p>
          <p className="text-gray-700">
            ② 회사는 회원의 귀책사유로 인한 유료서비스 이용의 장애에 대하여는 회사의 귀책사유가 없는 한 책임을 지지 않습니다.
          </p>
          <p className="text-gray-700">
            ③ 회사는 회원 상호간 또는 회원과 제3자간에 유료서비스를 매개로 하여 발생한 분쟁 등에 대하여 회사의 귀책사유가 없는 한 책임을 지지 않습니다.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">[부칙]</h2>
          <p className="text-gray-700">이 약관은 2025년 3월 1일부터 적용됩니다.</p>
        </section>
      </div>
    </>

  );
};

export default PrivatePayment;
