import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";

const WelcomePayClose = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://tstdpay.paywelcome.co.kr/stdjs/INIStdPay_close.js";
    script.charset = "UTF-8";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div onClick={() => { navigate('/', { replace: true }) }}>홈으로 돌아가기</div>;
};

export default WelcomePayClose;
