import React, { useEffect, useMemo, useState } from 'react';

import HeaderWithBackButton from 'components/HeaderWithBackButton';
import { useInView } from 'react-intersection-observer';
import { useCastingcallScrap, useProfileScrap, useProductionScrap } from 'apis/scrap';
import NoticeCard from 'components/NoticeCard';
import { useNavigate, useNavigationType } from 'react-router';
import { splitBirth } from 'utils/onBirth';
import ArtistListCard from 'components/ArtistListCard';
import StaffCard from 'components/StaffCard';

const Scrap = () => {
    const navigate = useNavigate();
    const navigationType = useNavigationType();

    const [tabName, setTabName] = useState("공고");
    const tab = ["공고",
        // "작품",
        "아티스트", "스탭"]

    const { ref, inView } = useInView({
        threshold: 0.5,
    });

    useEffect(() => {
        const savedTab = localStorage.getItem("scrapTab");
        if (savedTab && navigationType === "POP") {
            setTabName(savedTab);
        } else {
            setTabName("공고");
            localStorage.removeItem("scrapTab")
        }

    }, [navigationType]);

    const handleTabChange = (newTab: string) => {
        if (tabName !== newTab) {
            setTabName(newTab);
            localStorage.setItem("scrapTab", newTab);
        }
    };

    // const product = useProductionScrap({}, tabName === "작품");
    const profile = useProfileScrap({ type: tabName === "아티스트" ? "ARTIST" : "STAFF" }, tabName === "아티스트" || tabName === "스탭");
    const casting = useCastingcallScrap({}, tabName === "공고");

    const useTabScrapData = () => {
        switch (tabName) {
            case "공고":
                return casting
            // case "작품":
            //     return product
            case "아티스트":
            case "스탭":
                return profile
            default:
                return { data: null, fetchNextPage: () => { }, hasNextPage: false };
        }
    }

    const { data, fetchNextPage, hasNextPage } = useTabScrapData();

    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [inView, hasNextPage, fetchNextPage]);

    const scrapLists = useMemo(
        () => (data ? data.pages.flatMap((page) => page.data.content) : []),
        [data]
    );

    return (
        <>
            <HeaderWithBackButton title={'스크랩'} />

            <div className="border-b border-Gray-3 mt-5 px-5">
                <div className="flex items-start gap-4 BCaption14">
                    {tab.map((item) => {
                        return (
                            <div key={item}
                                onClick={() => { handleTabChange(item) }}
                                className={`pb-4 flex-1 text-center cursor-pointer px-1 ${item === tabName ? "border-b-2 border-Gray09 text-Gray09" : "border-none text-Gray04"}`}>
                                {item}
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className="p-4">
                {(() => {
                    switch (tabName) {
                        case "공고":
                            return (
                                <>
                                    {scrapLists.length === 0 ? (
                                        <div className="mt-36 items-center text-center">
                                            <div className="text-Gray05 RBody14">스크랩한 공고가 없어요.</div>
                                        </div>
                                    ) : (
                                        <div className='flex flex-col gap-2'>
                                            {scrapLists?.map((item, i) => {
                                                const now = new Date();
                                                const deadlineDate = new Date(item?.deadlineDate);

                                                const timeDifference = deadlineDate.getTime() - now.getTime();
                                                const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

                                                const isDeadlineSoon = item?.deadlineDate !== null && dayDifference < 2;
                                                return (
                                                    <NoticeCard
                                                        key={i}
                                                        castingCallId={item?.castingCallId}
                                                        favoriteCastingCallId={item?.favoriteCastingCallId}
                                                        title={item?.title}
                                                        isBookmark={true}
                                                        productionTitle={item?.productionTitle}
                                                        src={item?.thumbnailUrl}
                                                        type={item?.productionFormat?.label}
                                                        gender={item?.gender?.label}
                                                        age={`${item?.minAge}세 ~ ${item?.maxAge}세`}
                                                        deadlineSoon={isDeadlineSoon}
                                                        deadline={
                                                            item?.deadlineDate
                                                                ? `${item?.deadlineDate}까지`
                                                                : "캐스팅 확정 시 마감"
                                                        }
                                                        onClick={() => {
                                                            navigate(`/casting/${item?.castingCallId}`);
                                                        }}
                                                    />
                                                );
                                            })}
                                        </div>
                                    )
                                    }
                                </>
                            );
                        // case "작품":
                        //     return (
                        //         <div className='flex flex-col gap-2'>
                        //             <div>1</div>
                        //             <div>2</div>
                        //         </div>
                        //     );
                        case "아티스트":
                            return (
                                <>
                                    {scrapLists.length === 0 ? (
                                        <div className="mt-36 items-center text-center">
                                            <div className="text-Gray05 RBody14">스크랩한 공고가 없어요.</div>
                                        </div>
                                    ) : (
                                        <div className='grid grid-cols-3 gap-y-4 gap-x-2'>
                                            {scrapLists?.map((item, i) => {
                                                return (
                                                    <ArtistListCard
                                                        Bookmark
                                                        key={i}
                                                        profileId={item?.profileId}
                                                        onClick={() => {
                                                            navigate(`/artist/${item?.profileId}`);
                                                        }}
                                                        isMembership={item?.isMembership}
                                                        title={item?.profileName}
                                                        src={item?.thumbnailUrl}
                                                        subTitle={`${splitBirth(item?.birthDate)}`}
                                                        favoriteId={item?.favoriteProfileId}
                                                        isBookmark={true}
                                                    />
                                                );
                                            })}
                                        </div>
                                    )}
                                </>
                            );
                        case "스탭":
                            return (
                                <>
                                    {scrapLists.length === 0 ? (
                                        <div className="mt-36 items-center text-center">
                                            <div className="text-Gray05 RBody14">스크랩한 공고가 없어요.</div>
                                        </div>
                                    ) : (

                                        <div className='flex flex-col gap-2'>
                                            {scrapLists?.map((item, i) => {
                                                return (
                                                    <StaffCard
                                                        key={i}
                                                        isCasting={item?.isCasting}
                                                        isPending={item?.status?.code === "PENDING"}
                                                        image={item?.thumbnailUrl}
                                                        role={item?.role.label}
                                                        favoriteId={item?.favoriteProfileId}
                                                        isBookmark={true}
                                                        profileId={item?.profileId}
                                                        agency={item?.agency}
                                                        name={item?.profileName}
                                                        onClick={() => {
                                                            navigate(`/staff/${item?.profileId}`, {
                                                                state: { role: item?.role?.code },
                                                            })
                                                        }}
                                                    />
                                                );
                                            })}
                                        </div>
                                    )}
                                </>
                            );
                        default:
                            return <div>잘못된 탭입니다.</div>;
                    }
                })()}
                {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
            </div>

        </>
    )
}
export default Scrap;