import React from "react";

import { useNavigate } from "react-router-dom";

import Profile from "assets/cv_logo_profile.png";
import Header from "components/Header";
import {
  MY_ROLE,
  MEMBER_APPROVED,
} from "utils/onRoleStatus";
import Chip from "components/Chip";
import { FillButton } from "components/FillButton";
import { usePoint } from "apis/point";
import useLoginInfo from "stores/loginInfo";
import Artist from "./_components/Artist";
import Manager from "./_components/Manager";
import Staff from "./_components/Staff";

const Mypage = () => {
  const navigate = useNavigate();

  const { data: pointData } = usePoint();
  const { loginInfo } = useLoginInfo();

  const isApproved = MEMBER_APPROVED();
  const jobRole = MY_ROLE();

  console.log(jobRole)

  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

  return (
    <>
      <Header title="더보기" />
      <div className="pb-[70px] pt-2 mx-auto">
        <div>
          <div className="flex items-center justify-between px-5">
            <div onClick={() => {
              navigate("/mypage");
            }} className="flex items-center gap-2 cursor-pointer">
              {MEDIA_URL && (
                <img
                  src={
                    loginInfo?.thumbnailUrl ? MEDIA_URL + loginInfo?.thumbnailUrl : Profile
                  }
                  alt={"profileThumbnail"}
                  className="w-7 h-7 rounded-full object-cover"
                />
              )}
              <div className="BBody18 text-Gray09">{loginInfo?.name}</div>
              {!isApproved && (
                <Chip className="!cursor-default" title={"승인대기"} />
              )}
            </div>
            {(jobRole === "ROLE_MANAGER" || jobRole === "ROLE_ARTIST") &&
              <div className="flex flex-col gap-2">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <div
                      onClick={() => {
                        navigate("/point");
                      }}
                      className="text-Blue04 cursor-pointer !BBody18"
                    >
                      {pointData && pointData.data.toLocaleString()}
                    </div>
                    <FillButton
                      onClick={() => {
                        navigate("/point/charge");
                      }}
                      variant="Membership"
                      size="S"
                      text="충전"
                    />
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="pt-7 MBody18 text-Gray09">
            {(() => {
              switch (jobRole) {
                case "ROLE_ARTIST":
                  return <Artist />
                case "ROLE_MANAGER":
                  return <Manager />
                default:
                  return <Staff />
              }
            }
            )()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Mypage;
