import React, { useEffect, useState } from 'react';

import HeaderWithBackButton from 'components/HeaderWithBackButton';
import { useNavigate, useSearchParams } from 'react-router';
import useLoginInfo from 'stores/loginInfo';
import { useMyInfo } from 'apis/my';

import ManagerBanner from "assets/deliveryManagementBanner.png"
import { FillButton } from 'components/FillButton';
import { isMobileDevice } from 'utils/IsMobile';

import { ReactComponent as Arrow } from 'assets/faqArrow.svg'

import delivery01 from "assets/delivery01.png"
import delivery02 from "assets/delivery02.png"
import delivery03 from "assets/delivery03.png"
import delivery04 from "assets/delivery04.png"
import delivery05 from "assets/delivery05.png"
import delivery06 from "assets/delivery06.png"
import delivery07 from "assets/delivery07.png"
import delivery08 from "assets/delivery08.png"

import deliveryManagementLogo01 from "assets/delivery_management_logo_01.png"
import deliveryManagementLogo02 from "assets/delivery_management_logo_02.png"
import deliveryManagementLogo03 from "assets/delivery_management_logo_03.png"
import deliveryManagementLogo04 from "assets/delivery_management_logo_04.png"
import deliveryManagementLogo05 from "assets/delivery_management_logo_05.png"
import deliveryManagementLogo06 from "assets/delivery_management_logo_06.png"

import deliveryManagementLogo07 from "assets/delivery_management_logo_07.png"
import deliveryManagementLogo08 from "assets/delivery_management_logo_08.png"
import deliveryManagementLogo09 from "assets/delivery_management_logo_09.png"
import deliveryManagementLogo10 from "assets/delivery_management_logo_10.png"
import deliveryManagementLogo11 from "assets/delivery_management_logo_11.png"
import deliveryManagementLogo12 from "assets/delivery_management_logo_12.png"

import deliveryManagementLogo13 from "assets/delivery_management_logo_13.png"
import deliveryManagementLogo14 from "assets/delivery_management_logo_14.png"
import deliveryManagementLogo15 from "assets/delivery_management_logo_15.png"
import deliveryManagementLogo16 from "assets/delivery_management_logo_16.png"
import deliveryManagementLogo17 from "assets/delivery_management_logo_17.png"
import deliveryManagementLogo18 from "assets/delivery_management_logo_18.png"

import management_shadow from "assets/management_shadow.png"
import { Toast } from 'hooks/useToast';
import requestToCastingVote from 'apis/api';
import ScrollToTop from 'components/ScrollToTop';

const DeliverySubscribe = () => {
    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_API_URL;
    const URL = process.env.REACT_APP_URL;
    const [searchParams] = useSearchParams();
    const message = searchParams?.get("message");

    useEffect(() => {
        Toast.error(message);
    }, [message]);

    const { data } = useMyInfo();
    const { loginInfo } = useLoginInfo();

    const info = data?.data;
    const [openIndexes, setOpenIndexes] = useState<number[]>([]);

    const toggleFAQ = (index: number) => {
        setOpenIndexes((prev) =>
            prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
        );
    };

    const faqData = [
        { id: 0, question: "담당자가 제 프로필을 정말 확인할까요?", answer: "캐스팅보트의 프로필 딜리버리 서비스는 문앞이나 로비에 두고 가는 방식이 아닌, 캐스팅 담당자 또는 제작사, 매니지먼트 담당자를 직접 만나 프로필을 전달하고 홍보해 드리는 서비스예요. 프로필을 전달받은 담당자가 프로필 QR코드를 스캔하면 배우님께 열람 알림톡이 발송됩니다.", desc: "* QR프로필 4월 중 출시 예정" },
        { id: 1, question: "프로필 보내기와 프로필 딜리버리의 다른 점이 무엇인가요?", answer: "프로필 보내기는 프로필 투어를 온라인으로 옮겨 놓은, 찾아가지 않고도 스탭 회원에게 직접 프로필을 보내서 피드백을 받고 작품에 참여할 기회도 얻을 수 있는 서비스예요. 딜리버리는 배우님의 종이 프로필을 전달하는 기존의 오프라인 프로필 투어로 진행되지만 캐스팅보트 담당자가 배우님의 매니저가 되어 대신 홍보해 드리고, QR코드를 통해 온라인 프로필과 열람 확인이 가능한 서비스예요. 또, 오프라인으로 프로필을 전달받은 담당자가 QR코드를 스캔하면 캐스팅보트 플랫폼에서 배우님께 캐스팅 제안을 드릴 수도 있어요. \n\n피드백을 받고 싶다면 프로필 보내기를, 종이 프로필을 전달하고 싶다면 프로필 딜리버리 서비스를 이용해 보세요!", },
        { id: 3, question: "멤버십에 가입하지 않아도 딜리버리 서비스를 이용할 수 있나요?", answer: "프로필 딜리버리 서비스는 멤버십과 별개의 서비스로, 멤버십에 가입하지 않은 배우님도 이용할 수 있습니다. 프로필 딜리버리를 통해 캐스팅 제안 받은 공고에도 무료로 지원할 수 있으니 부담없이 사용해 주세요." },
        { id: 2, question: "프로필 딜리버리는 어떻게 구성되나요?", answer: "캐스팅디렉터, 제작사, 매니지먼트 등 캐스팅보트와 협업하는 업체 중 5곳에 전달됩니다. 추후에는 원하는 옵션을 직접 선택하는 방식으로 진행될 예정이에요. 더욱 폭넓은 선택지를 제공할테니 많은 관심 부탁드려요." },
    ];

    const up = [deliveryManagementLogo01, deliveryManagementLogo02, deliveryManagementLogo03, deliveryManagementLogo04, deliveryManagementLogo05, deliveryManagementLogo06]
    const center = [deliveryManagementLogo07, deliveryManagementLogo08, deliveryManagementLogo09, deliveryManagementLogo10, deliveryManagementLogo11, deliveryManagementLogo12]
    const down = [deliveryManagementLogo13, deliveryManagementLogo14, deliveryManagementLogo15, deliveryManagementLogo16, deliveryManagementLogo17, deliveryManagementLogo18]

    const Delivery = (type: "PC" | "MOBILE") => {
        requestToCastingVote({
            method: "POST",
            url: `/payments/welcomepay/delivery`,
            data: {
                totalPrice: 44000,
                orderName: "오프라인 딜리버리",
                paymentCategory: "OFFLINE_DELIVERY",
                pgType: "WELCOME",
                orderAmount: 44000,
                deviceType: type,
                offlineDeliveryTypeId: 1,
                optionGroupIds: [1],
                profileIds: [loginInfo?.profileId!],
                redirectUrl: '/delivery/complete'
            },
        }).then((res) => {
            const data = res.data.data;

            if (!data) {
                Toast.error("결제 데이터가 없습니다.");
                return;
            }

            if (type === "PC") {
                if (!window?.INIStdPay) {
                    Toast.error("INIStdPay.js가 로드되지 않았습니다.");
                    return;
                }

                const form = document.getElementById("sendPayFormId") as HTMLFormElement;
                if (!form) return;

                requestAnimationFrame(() => {
                    (form.elements.namedItem("signature") as HTMLInputElement).value = data.signature;
                    (form.elements.namedItem("timestamp") as HTMLInputElement).value = data.timestamp;
                    (form.elements.namedItem("mKey") as HTMLInputElement).value = data.mKey;
                    (form.elements.namedItem("oid") as HTMLInputElement).value = data.orderId;
                    (form.elements.namedItem("mid") as HTMLInputElement).value = data.mid;
                    (form.elements.namedItem("price") as HTMLInputElement).value = data.price;

                    window.INIStdPay?.pay("sendPayFormId");
                });

            } else if (type === "MOBILE") {
                const form = document.getElementById("sendPayFormMobile") as HTMLFormElement;
                if (!form) return;

                requestAnimationFrame(() => {
                    (form.elements.namedItem("P_SIGNATURE") as HTMLInputElement).value = data.signature;
                    (form.elements.namedItem("P_TIMESTAMP") as HTMLInputElement).value = data.timestamp;
                    (form.elements.namedItem("P_OID") as HTMLInputElement).value = data.orderId;
                    (form.elements.namedItem("P_NOTI") as HTMLInputElement).value = data.orderId;
                    (form.elements.namedItem("P_MID") as HTMLInputElement).value = data.mid;
                    (form.elements.namedItem("P_AMT") as HTMLInputElement).value = data.price;

                    // 테스트 결제 url
                    form.action = "https://tmobile.paywelcome.co.kr/smart/wcard/";
                    // 실제 결제 url
                    // form.action = "https://mobile.paywelcome.co.kr/smart/wcard/";
                    form.target = "_self";
                    form.submit();
                });
            }
        }).catch((err) => {
            Toast.error("결제 실패했어요.");
        })
    }

    return (
        <>
            <ScrollToTop />
            <div className="pb-[124px] bg-[#121212] h-full text-Gray00 shadow-[0_0px_20px_rgba(0,0,0,0.1)] ">
                <HeaderWithBackButton darkMode headerColorClassName='bg-[#121212]' title={"프로필 딜리버리"}>
                    <div onClick={() => {
                        navigate("/delivery/history")
                    }} className='BCaption14 text-Blue04 cursor-pointer'>신청내역</div>
                </HeaderWithBackButton>
                <div className='mt-10'>
                    <img src={delivery01} alt="" />
                </div>
                <div className='mt-40'>
                    <img src={delivery02} alt="" />
                </div>
                <div className='mt-40 px-5'>
                    <img src={delivery03} alt="" />
                </div>
                <div className='mt-32 px-5'>
                    <img src={delivery04} alt="" />
                    <div className="flex relative flex-col gap-1 mt-[60px] overflow-hidden">
                        <img className='absolute left-0 top-0 z-10 h-[230px] opacity-50' src={management_shadow} alt="" />
                        <div className="flex items-start w-[464px] relative overflow-hidden">
                            <div className="flex items-center gap-1 flex-nowrap rolling-image up">
                                {up.concat(up).map((item, idx) => (
                                    <img key={idx} className="w-[74px] h-[74px] flex-shrink-0" src={item} alt="" />
                                ))}
                            </div>
                        </div>
                        <div className="flex items-start w-[464px] relative overflow-hidden">
                            <div className="flex items-center gap-1 flex-nowrap rolling-image down">
                                {center.concat(center).map((item, idx) => (
                                    <img key={idx} className="w-[74px] h-[74px] flex-shrink-0" src={item} alt="" />
                                ))}
                            </div>
                        </div>
                        <div className="flex items-start w-[464px] relative overflow-hidden">
                            <div className="flex items-center gap-1 flex-nowrap rolling-image up">
                                {down.concat(down).map((item, idx) => (
                                    <img key={idx} className="w-[74px] h-[74px] flex-shrink-0" src={item} alt="" />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-40 px-5'>
                    {/* 20년 경력의 매니저 출신 */}
                    <img src={delivery05} alt="" />
                </div>
                <div className='mt-[180px] px-5'>
                    {/* 프로필 딜리버리 절차 */}
                    <img src={delivery06} alt="" />
                </div>
                <div className='mt-40 px-5 text-center'>
                    {/* 신청기간 */}
                    <img src={delivery07} alt="" />
                    <div className='mt-10 mb-4 BHead24'>
                        매달 1일부터 말일까지
                    </div>
                    <div className='MBody16 text-Gray05'>
                        <div>4월 nn일 인쇄</div>
                        <div>4월 nn일 딜리버리 시작</div>
                    </div>
                </div>
                <div className='px-5 mt-40'>
                    <img src={delivery08} alt="" />
                    <div className='mt-[60px]'>
                        {faqData.map((item, i) => {
                            return (
                                <div key={item.id} onClick={() => {
                                    toggleFAQ(i)
                                }} className='border-b border-spacing-0.5 border-Gray00 first:border-t py-7 cursor-pointer'>
                                    <div className='flex items-start gap-2'>
                                        <div className='flex-1 BBody16'>{item.question}</div>
                                        <Arrow className={`${openIndexes?.includes(i) ? "" : "rotate-90"} transition-all`} />
                                    </div>
                                    {openIndexes?.includes(i) &&
                                        <>
                                            <div className='text-Blue02 MCaption14 mt-4 whitespace-break-spaces'>{item.answer}</div>
                                            {item.desc &&
                                                <div className='text-Blue02 MCaption10 mt-[18px]'>{item.desc}</div>
                                            }
                                        </>
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>
                <img src={ManagerBanner} className="my-[60px]" alt="" />

                <div className="px-5 text-Gray05">
                    <div className="BCaption12 mb-2">유의사항</div>
                    <div className="flex flex-col gap-2">
                        <div>
                            <div className="MCaption12 ">구매안내</div>
                            <ol className="list-disc pl-4 RCaption10">
                                <li>mm월 dd일부터 딜리버리 시작되는 상품입니다.</li>
                                <li>결제 금액은 부가세(VAT)가 포함된 가격입니다.</li>
                                <li>미성년 회원의 결제는 원칙적으로 법정대리인의 명의 또는 동의 하에 이루어져야 하고, 법정대리인은 본인 동의 없이 체결된 자녀(미성년자)의 계약을 취소할 수 있습니다.</li>
                            </ol>
                        </div>
                        <div>
                            <div className="MCaption12">환불안내</div>
                            <ol className="list-disc pl-4 RCaption10">
                                <li>인쇄가 시작된 이후에는 결제 취소가 불가능합니다. 결제 취소를 원하신다면 mm월 dd일까지 환불 신청 부탁드립니다.</li>
                                <li>환불 신청은 고객센터로 1:1 문의 부탁드립니다.</li>
                            </ol>
                        </div>
                        <div>
                            <div className="MCaption12">기타안내</div>
                            <ol className="list-disc pl-4 RCaption10">
                                <li>기타 문의 사항은 고객센터로 1:1 문의 부탁드립니다.</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="fixed bottom-0 px-5 max-w-[400px] bg-opacity-100 pb-8 z-50 left-2/4 w-full -translate-x-2/4">
                    <FillButton
                        onClick={() => {
                            Delivery(isMobileDevice() ? "MOBILE" : "PC")
                        }}
                        variant="Membership"
                        size={"B"}
                        text="프로필 딜리버리 신청하기"
                    />
                </div>
            </div>

            <form id="sendPayFormId" name="sendPayFormId" method="POST">
                <input type="hidden" name="version" value="1.0" />
                <input type="hidden" name="mid" value="welcometst" />
                <input type="hidden" name="goodname" value="delivery" />
                <input type="hidden" name="oid" value="welcometst_1733221645882" />
                <input type="hidden" name="price" value="10" />
                <input type="hidden" name="currency" value="WON" />
                <input type="hidden" name="buyername" value={info?.name} />
                <input type="hidden" name="buyertel" value={info?.phoneNumber} />
                <input type="hidden" name="buyeremail" value={info?.email} />
                <input type="hidden" name="timestamp" value="API" />
                <input type="hidden" name="signature" value="API" />
                <input
                    type="hidden"
                    name="returnUrl"
                    value={`${API_URL}/payments/welcomepay/return`}
                />
                <input type="hidden" name="mKey" value="API" />
                {/* <input
                    type="hidden"
                    name="closeUrl"
                    value={`http://localhost:3000/welcomepay/close`}
                /> */}
                <input
                    type="hidden"
                    name="closeUrl"
                    value={`${URL}/welcomepay/close`}
                />
                <input type="hidden" name="popupUrl" value="http://localhost:3000" />
                <input type="hidden" name="gopaymethod" value="Card" />
            </form>

            <form id="sendPayFormMobile" method="POST" accept-charset="euc-kr">
                <input type="hidden" name="MID" value="welcometst" />
                <input type="hidden" name="P_MID" value="API" />
                <input type="hidden" name="P_OID" value="API" />
                <input type="hidden" name="P_NOTI" value="welcometst_1733221645882" />
                <input type="hidden" name="P_AMT" value="10" />
                <input type="hidden" name="P_UNAME" value={info?.name} />
                <input type="hidden" name="P_MNAME" value="castingvote" />
                <input type="hidden" name="P_GOODS" value="delivery" />
                <input type="hidden" name="P_MOBILE" value={info?.phoneNumber} />
                <input type="hidden" name="P_EMAIL" value={info?.email} />
                <input type="hidden" name="P_CHARSET" value="utf8" />
                <input
                    type="hidden"
                    name="P_NEXT_URL"
                    value={`${API_URL}/payments/welcomepay/mobile/return`}
                />
                <input type="hidden" name="P_RETURN_URL" value={`${API_URL}`} />
                <input
                    type="hidden"
                    name="P_NOTI_URL"
                    value="http://10.10.1.111:8080/api/payments/welcomepay/mobile/return"
                />
                <input type="hidden" name="P_TIMESTAMP" value="1733221645882" />
                <input
                    type="hidden"
                    name="P_SIGNATURE"
                    value="67f64aa43b227f7e019b75768678edb9e954e7ebc77b9e3291b84f9052ebdd4c"
                />
                <input type="hidden" name="P_HPP_METHOD" value="2" />
                <input
                    type="hidden"
                    name="P_RESERVED"
                    value="twotrs_isp=Y&twotrs_isp_noti=N&apprun_check=Y&below1000=Y"
                />
            </form>
        </>
    );
};

export default DeliverySubscribe;