import React, { useEffect, useState } from "react";

import ButtonBackground from "components/ButtonBackground";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import PointChargeCard from "components/PointCharge";

import { FillButton } from "components/FillButton";
import { useSearchParams } from "react-router";
import { Toast } from "hooks/useToast";
import { usePoint } from "apis/point";
import { useMyInfo } from "apis/my";
import CheckboxCircle from "components/Checkbox/CheckboxCircle";
import { useCreatePayment } from "apis/payment";
import { isMobileDevice } from "utils/IsMobile";

import requestToCastingVote from 'apis/api';


const PointCharge = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const URL = process.env.REACT_APP_URL;

  const [searchParams] = useSearchParams();
  const message = searchParams?.get("message");

  useEffect(() => {
    Toast.error(message);
  }, [message]);

  const [selectedPoint, setSelectedPoint] = useState<string | null>("");
  const [agreeCharge, setAgreeCharge] = useState<boolean>(false);

  const { data } = useMyInfo();
  const info = data?.data;

  const { data: myPoint, isLoading } = usePoint();

  const pointType = [
    { id: 0, price: "11000" },
    { id: 1, price: "33000" },
    { id: 2, price: "55000" },
    { id: 3, price: "110000" },
  ];

  const payCheck = selectedPoint !== "" && agreeCharge === true;

  const PointCharge = (type: "PC" | "MOBILE") => {
    requestToCastingVote({
      method: "POST",
      url: `/payments/welcomepay`,
      data: {
        totalPrice: selectedPoint,
        orderName: "포인트",
        paymentCategory: "POINT",
        pgType: "WELCOME",
        deviceType: type,
        orderAmount: selectedPoint,
        redirectUrl: '/point'
      },
    }).then((res) => {
      const data = res.data.data;

      if (type === "PC") {
        if (!window?.INIStdPay) {
          Toast.error("INIStdPay.js가 로드되지 않았습니다.");
          return;
        }

        const form = document.getElementById("sendPayFormId") as HTMLFormElement;
        if (!form) return;

        requestAnimationFrame(() => {
          (form.elements.namedItem("signature") as HTMLInputElement).value = data.signature;
          (form.elements.namedItem("timestamp") as HTMLInputElement).value = data.timestamp;
          (form.elements.namedItem("mKey") as HTMLInputElement).value = data.mKey;
          (form.elements.namedItem("oid") as HTMLInputElement).value = data.orderId;
          (form.elements.namedItem("mid") as HTMLInputElement).value = data.mid;
          (form.elements.namedItem("price") as HTMLInputElement).value = data.price;

          window.INIStdPay?.pay("sendPayFormId");
        });

      } else if (type === "MOBILE") {
        const form = document.getElementById("sendPayFormMobile") as HTMLFormElement;
        if (!form) return;

        requestAnimationFrame(() => {
          (form.elements.namedItem("P_SIGNATURE") as HTMLInputElement).value = data.signature;
          (form.elements.namedItem("P_TIMESTAMP") as HTMLInputElement).value = data.timestamp;
          (form.elements.namedItem("P_OID") as HTMLInputElement).value = data.orderId;
          (form.elements.namedItem("P_NOTI") as HTMLInputElement).value = data.orderId;
          (form.elements.namedItem("P_MID") as HTMLInputElement).value = data.mid;
          (form.elements.namedItem("P_AMT") as HTMLInputElement).value = data.price;

          // 테스트 결제 url
          form.action = "https://tmobile.paywelcome.co.kr/smart/wcard/";
          // 실제 결제 url
          // form.action = "https://mobile.paywelcome.co.kr/smart/wcard/";
          form.target = "_self";
          form.submit();
        });
      }
    }).catch((err) => {
      Toast.error("결제 실패했어요.");
    })
  }

  return (
    <>
      <HeaderWithBackButton title={"포인트"} />
      <div className="p-5 pb-24">
        <div>
          <div className="flex items-center gap-2">
            <div className="text-Gray05 MBody16">내 포인트</div>
            <div className="text-Blue04 BBody18">
              {myPoint?.data ? myPoint?.data?.toLocaleString() : "0"} P
            </div>
          </div>
          <div className="flex flex-col gap-2 mt-4">
            {pointType.map((item) => {
              return (
                <PointChargeCard
                  onClick={() => {
                    if (selectedPoint === item.price) {
                      setSelectedPoint("");
                    } else {
                      setSelectedPoint(item.price);
                    }
                  }}
                  selected={selectedPoint === item.price}
                  key={item.id}
                  price={item.price}
                />
              );
            })}
          </div>
        </div>
        <div className="p-4 bg-Gray01 my-5 rounded-md">
          <ol className="list-disc list-inside MBody14 text-Gray05">
            <li>1포인트는 1원입니다.</li>
            <li>포인트 사용 시, 적립 순서에 따라 오래된 포인트부터 차감됩니다.</li>
            <li>사용하지 않은 포인트에 대해서 환불이 가능하며, 환불 시 서비스 수수료 10%를 공제한 금액이 환불됩니다.</li>
            <li>미성년자의 경우 결제 시 법정대리인의 동의가 필요하며, 이에 따라 법정대리인의 동의를 받았음을 확인할 수 있습니다.</li>
            <li>포인트는 충전일로부터 5년간 유효하며, 유효기간이 경과한 포인트는 자동 소멸됩니다.</li>
          </ol>
        </div>
        <div>
          <CheckboxCircle
            onChange={(e) => {
              setAgreeCharge(e.target.checked);
            }}
            checked={agreeCharge}
            id="1"
            title={
              "상품, 가격, 할인 정보, 유의 사항 등을 확인하였으며 구매에 동의합니다. (필수)"
            }
          />
        </div>
        <ButtonBackground>
          <FillButton
            isLoading={isLoading}
            disabled={!payCheck}
            onClick={() => {
              PointCharge(isMobileDevice() ? "MOBILE" : "PC");
            }}
            variant="Membership"
            size={"B"}
            text={`${selectedPoint !== ""
              ? Number(selectedPoint).toLocaleString("ko-KR")
              : ""
              }포인트 충전하기`}
          />
        </ButtonBackground>
      </div>
      <form id="sendPayFormId" name="sendPayFormId" method="POST">
        <input type="hidden" name="version" value="1.0" />
        <input type="hidden" name="mid" value="welcometst" />
        <input type="hidden" name="goodname" value="point" />
        <input type="hidden" name="oid" value="welcometst_1733221645882" />
        <input type="hidden" name="price" value="10" />
        <input type="hidden" name="currency" value="WON" />
        <input type="hidden" name="buyername" value={info?.name} />
        <input type="hidden" name="buyertel" value={info?.phoneNumber} />
        <input type="hidden" name="buyeremail" value={info?.email} />
        <input type="hidden" name="timestamp" value="API" />
        <input type="hidden" name="signature" value="API" />
        <input
          type="hidden"
          name="returnUrl"
          value={`${API_URL}/payments/welcomepay/return`}
        />
        <input type="hidden" name="mKey" value="API" />
        {/* <input
          type="hidden"
          name="closeUrl"
          value={`http://localhost:3000/welcomepay/close`}
        /> */}
        <input
          type="hidden"
          name="closeUrl"
          value={`${URL}/welcomepay/close`}
        />
        <input type="hidden" name="popupUrl" value="http://localhost:3000" />
        <input type="hidden" name="gopaymethod" value="Card" />
      </form>

      <form id="sendPayFormMobile" method="POST" accept-charset="euc-kr">
        <input type="hidden" name="MID" value="welcometst" />
        <input type="hidden" name="P_MID" value="API" />
        <input type="hidden" name="P_OID" value="API" />
        <input type="hidden" name="P_NOTI" value="welcometst_1733221645882" />
        <input type="hidden" name="P_AMT" value="10" />
        <input type="hidden" name="P_UNAME" value={info?.name} />
        <input type="hidden" name="P_MNAME" value="castingvote" />
        <input type="hidden" name="P_GOODS" value="point" />
        <input type="hidden" name="P_MOBILE" value={info?.phoneNumber} />
        <input type="hidden" name="P_EMAIL" value={info?.email} />
        <input type="hidden" name="P_CHARSET" value="utf8" />
        <input
          type="hidden"
          name="P_NEXT_URL"
          value={`${API_URL}/payments/welcomepay/mobile/return`}
        />
        <input type="hidden" name="P_RETURN_URL" value={`${API_URL}`} />
        <input
          type="hidden"
          name="P_NOTI_URL"
          value="http://10.10.1.111:8080/api/payments/welcomepay/mobile/return"
        />
        <input type="hidden" name="P_TIMESTAMP" value="1733221645882" />
        <input
          type="hidden"
          name="P_SIGNATURE"
          value="67f64aa43b227f7e019b75768678edb9e954e7ebc77b9e3291b84f9052ebdd4c"
        />
        <input type="hidden" name="P_HPP_METHOD" value="2" />
        <input
          type="hidden"
          name="P_RESERVED"
          value="twotrs_isp=Y&twotrs_isp_noti=N&apprun_check=Y&below1000=Y"
        />
      </form>
    </>
  );
};

export default PointCharge;
