import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ReactComponent as ArrowLeft } from "assets/arrowLeft.svg";
import { ReactComponent as Filter } from "assets/icon_filter.svg";

import { ArtistList as ArtistListType } from "type/artist";
import { GENDER_LIST, HOBBY_LIST, JOB_LIST } from "utils/type";
import { splitBirth } from "utils/onBirth";

import { useNavigate, useNavigationType } from "react-router-dom";

import SearchBox from "components/SearchBox";
import Chip from "components/Chip";
import SideTab from "components/SideTab";
import TitleWrap from "components/TitleWrap";
import ArtistListCard from "components/ArtistListCard";
import Header from "components/Header";
import ReactVirtuosoGrid from "components/ReactVirtuosoGrid";
import RangeInput from "components/RangeInput";
import ButtonBackground from "components/ButtonBackground";
import { FillButton } from "components/FillButton";

import { useArtistProfiles } from "apis/profile";

type SearchType = "name" | "filmoTitle" | "filmoRole" | "agency" | "edu";

const ArtistList = () => {
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const LOCAL_STORAGE_KEY = "artistState";

  const [isSideTabOpen, setIsSideTabOpen] = useState(false);
  const [sort, setSort] = useState("createdAt,desc");
  const [searchValues, setSearchValues] = useState<Record<SearchType, string>>({
    name: "",
    filmoTitle: "",
    filmoRole: "",
    agency: "",
    edu: "",
  });

  const [selectedType, setSelectedType] = useState<SearchType>("name");
  const [gender, setGender] = useState("");
  const [hashtagIds, setHashtagIds] = useState<number[]>([]);
  const [jobs, setJobs] = useState<string[]>([]);
  const [hobbyIds, setHobbyIds] = useState<number[]>([]);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  const [artistData, setArtistData] = useState<ArtistListType>({
    gender: "",
    job: "",
    jobs: "",
    ageGoe: null,
    ageLoe: null,
    heightGoe: null,
    heightLoe: null,
    weightGoe: null,
    weightLoe: null,
    hashtagIds: "",
    hobbyIds: "",
  });

  const [ageRange, setAgeRange] = useState({ minValue: 0, maxValue: 100 });
  const [heightRange, setHeightRange] = useState({
    minValue: 0,
    maxValue: 220,
  });
  const [weightRange, setWeightRange] = useState({
    minValue: 0,
    maxValue: 150,
  });

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleAgeChange = (range: { minValue: number; maxValue: number }) => {
    const { minValue, maxValue } = range;
    setAgeRange({ minValue: minValue, maxValue: maxValue });
  };

  const handleHeightChange = (range: {
    minValue: number;
    maxValue: number;
  }) => {
    const { minValue, maxValue } = range;
    setHeightRange({ minValue: minValue, maxValue: maxValue });
  };

  const handleWeightChange = (range: {
    minValue: number;
    maxValue: number;
  }) => {
    const { minValue, maxValue } = range;
    setWeightRange({ minValue: minValue, maxValue: maxValue });
  };

  const handleOpenTab = () => setIsSideTabOpen(true);
  const handleCloseTab = () => setIsSideTabOpen(false);

  const [debouncedSearchValues, setDebouncedSearchValues] =
    useState(searchValues);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useArtistProfiles({
      name: debouncedSearchValues.name,
      filmographyTitle: debouncedSearchValues.filmoTitle,
      roleName: debouncedSearchValues.filmoRole,
      education: debouncedSearchValues.edu,
      agency: debouncedSearchValues.agency,
      sort: sort,
      ...artistData,
    });

  useEffect(() => {
    if (navigationType === "POP") {
      const savedState = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (savedState) {
        const parsedState = JSON.parse(savedState);
        setIsSideTabOpen(parsedState.isSideTabOpen || false);
        setSort(parsedState.sort || "createdAt,desc");
        setSearchValues(parsedState.searchValues || {});
        setSelectedType(parsedState.selectedType || "name");
        setGender(parsedState.gender || "");
        setHashtagIds(parsedState.hashtagIds || []);
        setJobs(parsedState.jobs || []);
        setHobbyIds(parsedState.hobbyIds || []);
        setArtistData(parsedState.artistData || {});
        setAgeRange(parsedState.ageRange || { minValue: 0, maxValue: 100 });
        setHeightRange(
          parsedState.heightRange || { minValue: 0, maxValue: 220 }
        );
        setWeightRange(
          parsedState.weightRange || { minValue: 0, maxValue: 150 }
        );
      }
    }
  }, [navigationType]);

  useEffect(() => {
    const stateToSave = {
      isSideTabOpen,
      sort,
      searchValues,
      selectedType,
      gender,
      hashtagIds,
      jobs,
      hobbyIds,
      artistData,
      ageRange,
      heightRange,
      weightRange,
    };
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(stateToSave));
  }, [
    isSideTabOpen,
    sort,
    searchValues,
    selectedType,
    gender,
    hashtagIds,
    jobs,
    hobbyIds,
    artistData,
    ageRange,
    heightRange,
    weightRange,
  ]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValues(searchValues);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [searchValues]);

  const loadMore = useCallback(() => {
    return setTimeout(() => {
      fetchNextPage();
    }, 300);
  }, []);

  const onTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSearchValues({
      name: "",
      filmoTitle: "",
      filmoRole: "",
      agency: "",
      edu: "",
    });

    setSelectedType(value as SearchType);
  };

  const options = [
    { id: 0, title: "이름", value: "name" },
    { id: 1, title: "작품", value: "filmoTitle" },
    { id: 2, title: "배역", value: "filmoRole" },
    { id: 3, title: "소속사", value: "agency" },
    { id: 4, title: "학력", value: "edu" },
  ];

  const allLists = useMemo(
    () => (data ? data?.pages?.flatMap((page) => page.data.content) : []),
    [data]
  );

  const renderChips = (list: any[], selected: any, setSelected: any) => {
    return list.map((item) => (
      <Chip
        key={item.id}
        selected={selected.includes(item.id)}
        onClick={() => {
          if (selected.includes(item.id)) {
            setSelected(selected.filter((i: number) => i !== item.id));
          } else {
            setSelected((prev: any) => [...prev, item.id]);
          }
        }}
        title={item.title}
      />
    ));
  };
  const handleReset = () => {
    setArtistData({
      hashtagIds: "",
      hobbyIds: "",
      gender: "",
      job: "",
    });
  };

  return (
    <>
      <Header title="아티스트" />
      <div className="pb-24 p-5">
        <SideTab isOpen={isSideTabOpen} onClose={handleCloseTab}>
          <div className="pl-2 p-4 flex items-center justify-between">
            <div
              onClick={handleCloseTab}
              className="MBody18 cursor-pointer flex items-center"
            >
              <ArrowLeft />
            </div>
            <div
              onClick={handleReset}
              className="BBody18 cursor-pointer text-Blue04"
            >
              초기화
            </div>
          </div>
          <div
            style={{ height: `${viewportHeight - 160}px` }}
            className="overflow-y-scroll "
          >
            <div className="pt-5 px-5 flex flex-col items-start">
              <TitleWrap title="성별">
                <div className="flex items-center flex-wrap gap-2">
                  {GENDER_LIST.map((item) => (
                    <Chip
                      key={item.id}
                      selected={gender === item.role}
                      onClick={() => {
                        if (gender === item.role) {
                          setGender("");
                        } else {
                          setGender(item.role);
                        }
                      }}
                      title={item.title}
                    />
                  ))}
                </div>
              </TitleWrap>
              <hr className="w-full my-4" />
              <TitleWrap title="직업">
                <div className="flex items-center flex-wrap gap-2">
                  {JOB_LIST.map((item) => (
                    <Chip
                      key={item.id}
                      selected={jobs.includes(item.title)}
                      onClick={() => {
                        if (jobs.includes(item.title)) {
                          setJobs(jobs.filter((i: string) => i !== item.title));
                        } else {
                          setJobs((prev: any) => [...prev, item.title]);
                        }
                      }}
                      title={item.title}
                    />
                  ))}
                </div>
              </TitleWrap>
              <hr className="w-full my-4" />
              <TitleWrap title="특기" subTitle="중복선택가능">
                <div className="flex items-center flex-wrap gap-2">
                  {renderChips(HOBBY_LIST, hobbyIds, setHobbyIds)}
                </div>
              </TitleWrap>
              <hr className="w-full my-4" />
              <div className="w-full">
                <div className="mb-[60px]">나이</div>
                <RangeInput
                  min={0}
                  priceGap={5}
                  max={100}
                  onChange={handleAgeChange}
                />
              </div>
              <hr className="w-full mb-4 mt-[60px]" />
              <div className="w-full">
                <div className="mb-[60px]">키</div>
                <RangeInput
                  min={0}
                  priceGap={5}
                  max={220}
                  onChange={handleHeightChange}
                />
              </div>
              <hr className="w-full mb-4 mt-[60px]" />
              <div className="w-full">
                <div className="mb-[60px]">몸무게</div>
                <RangeInput
                  min={0}
                  priceGap={5}
                  max={150}
                  onChange={handleWeightChange}
                />
              </div>
            </div>
            <ButtonBackground className="w-full">
              <FillButton
                onClick={() => {
                  setArtistData({
                    ...artistData,
                    hashtagIds: hashtagIds.join(","),
                    hobbyIds: hobbyIds.join(","),
                    gender,
                    jobs: jobs.join(","),
                    heightGoe:
                      heightRange.minValue !== 0 ? heightRange.minValue : null,
                    heightLoe:
                      heightRange.maxValue !== 220
                        ? heightRange.maxValue
                        : null,
                    weightGoe:
                      weightRange.minValue !== 0 ? weightRange.minValue : null,
                    weightLoe:
                      weightRange.maxValue !== 150
                        ? weightRange.maxValue
                        : null,
                    ageGoe: ageRange.minValue !== 0 ? ageRange.minValue : null,
                    ageLoe:
                      ageRange.maxValue !== 100 ? ageRange.maxValue : null,
                  });
                  setIsSideTabOpen(false);
                }}
                variant="Membership"
                className="w-full"
                size="B"
                text="필터 적용하기"
              />
            </ButtonBackground>
          </div>
        </SideTab>

        <div className="flex items-center gap-4">
          <SearchBox
            onDropDownChange={onTypeChange}
            onChange={(e) => {
              const value = e.target.value;
              setSearchValues({
                ...searchValues,
                [selectedType]: value,
              });
            }}
            value={searchValues[selectedType]}
            placeholder="이름, 작품, 배역, 소속사"
          >
            {options.map((item) => {
              return (
                <option key={item.id} value={item.value}>
                  {item.title}
                </option>
              );
            })}
          </SearchBox>
        </div>
        <div>
          <div className="flex items-center my-4 justify-between">
            <select
              onChange={(e) => {
                setSort(e.target.value);
              }}
              value={sort}
            >
              <option value="createdAt,desc">등록순 ↓</option>
              <option value="createdAt,asc">등록순 ↑</option>
              <option value="updatedAt,desc">최신순 ↓</option>
              <option value="updatedAt,asc">최신순 ↑</option>
              <option value="birthDate,desc">나이순 ↓</option>
              <option value="birthDate,asc">나이순 ↑</option>
            </select>
            <Filter className="cursor-pointer" onClick={handleOpenTab} />
          </div>
        </div>
        {data?.pages[0].data.content.length === 0 ? (
          <div className="mt-36 items-center text-center">
            <div className="text-Gray05 RBody14">등록된 프로필이 없어요.</div>
          </div>
        ) : (
          <div className="relative">
            <ReactVirtuosoGrid
              isLoading={isLoading}
              useWindowScroll
              data={isLoading ? Array.from({ length: 1 }) : allLists}
              overscan={100}
              hasNextPage={hasNextPage}
              listClassName="grid grid-cols-3 gap-y-4 gap-x-2"
              isFetchingNextPage={isFetchingNextPage}
              fetchNextPage={() => loadMore()}
              itemContent={(index, item) => {
                return (
                  <ArtistListCard
                    Bookmark
                    key={item?.profileId}
                    profileId={item?.profileId}
                    onClick={() => {
                      navigate(`${item?.profileId}`);
                    }}
                    isMembership={item?.isMembership}
                    title={item?.profileName}
                    src={item?.thumbnailUrl}
                    subTitle={`${splitBirth(item?.birthDate)}`}
                    favoriteId={item?.favoriteProfileId}
                    isBookmark={item?.isFavorite}
                  />
                );
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ArtistList;
