import React, { useState } from "react";

import { ReactComponent as Bookmark } from "assets/Bookmark.svg";
import { ReactComponent as Share } from "assets/share.svg"

import { useParams } from "react-router-dom";
import { ReactComponent as Arrow } from "assets/arrowDown.svg";
import { ReactComponent as SendFeedback } from "assets/sendFeedback.svg";
import { ReactComponent as Feedback } from "assets/icon_checkbox.svg";
import { ReactComponent as BlankStar } from "assets/blankStar.svg";
import { ReactComponent as Star } from "assets/star.svg";
import BottomButtonPopup from "components/BottomButtonPopup";
import BottomPopup from "components/BottomPopup";
import Textarea from "components/Textarea";
import Button from "components/Button";
import { Toast } from "hooks/useToast";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import Modal from "components/Modal";
import { handleCopyClipBoard } from "utils/onCopyClipBoard";
import ScrollToTop from "components/ScrollToTop";
import ButtonBackground from "components/ButtonBackground";
import ArtistAttachments from "./_components/ArtistAttachments";
import ArtistDesc from "./_components/ArtistDesc";
import ArtistFilmographies from "./_components/ArtistFilmographies";
import ArtistInfoCard from "./_components/ArtistInfoCard";
import Skeleton from "components/Skeleton";
import { useFeedbackReceivedProfile, useReceivedProfile } from "apis/deliveryProfile";
import { useShareLink } from "apis/profileShare";
import { LineButton } from "components/LineButton";

const ArtistReceived = () => {
  const [warning, setWarning] = useState(false);
  const [tabId, setTabId] = useState(0);

  const [feedbackScore, setFeedbackScore] = useState<number>(0);
  const { artistId } = useParams();

  const [review, setReview] = useState<string>("");
  const [copyModal, setCopyModal] = useState(false);
  const [profileId, setProfileId] = useState<null | number>(null);
  const [sendFeedback, setSendFeedback] = useState(false);
  const [feedback, setFeedback] = useState(false);

  const profileTab = [
    { id: 0, title: "프로필" },
  ]

  const { data, isFetching, refetch } = useReceivedProfile(
    Number(artistId)
  );

  const ArtistData = data?.data;

  const isButtonDisabled = review.length < 50 || feedbackScore === 0;

  const ratingStartValue = () => {
    let result = [];
    for (let i = 0; i < 5; i++) {
      result.push(
        <div
          key={i + 1}
          onClick={() => {
            setFeedbackScore(i + 1);
          }}
        >
          {i + 1 <= feedbackScore ? <Star className="w-8 h-8" /> : <BlankStar className="w-8 h-8" />}
        </div>
      );
    }
    return result;
  };

  const readRatingStartValue = () => {
    let result = [];
    for (let i = 0; i < 5; i++) {
      result.push(
        <div key={i + 1}>
          {i + 1 <= ArtistData?.rating! ? <Star className="w-8 h-8" /> : <BlankStar className="w-8 h-8" />}
        </div>
      );
    }
    return result;
  };

  const { mutate } = useFeedbackReceivedProfile();

  const submitFeedback = () => {
    mutate(
      { id: Number(artistId), rating: feedbackScore, comments: review },
      {
        onSuccess: () => {
          Toast.success("피드백을 보냈어요");
          refetch();
          setSendFeedback(false);
        }
      }
    );
  };

  const { data: link } = useShareLink({
    id: profileId,
    type: "DELIVERY_PROFILE",
    period: "MONTH",
  });

  const isHiddenStatus = ["CANCELED", "REFUND_REQUEST", "REFUNDED"].includes(ArtistData?.status.code);

  return (
    <>
      <HeaderWithBackButton
        phone={ArtistData?.phoneNumber}
        sms={ArtistData?.phoneNumber}
        email={ArtistData?.email}
        title=""
      >
        {/* <Bookmark className="cursor-pointer" /> */}
        <Share
          onClick={() => {
            setCopyModal(true);
            setProfileId(ArtistData?.deliveryProfileId!);
          }}
          className="cursor-pointer"
        />
      </HeaderWithBackButton>
      {isFetching ? (
        <Skeleton type="PROFILE_DETAIL" />
      ) : (
        <>
          <div className="pb-24 pt-5">
            <ScrollToTop />
            {ArtistData?.description && (
              <div className="px-5">
                <div className="p-5 w-full border mb-5 border-Gray03 bg-Gray01 rounded-md">
                  <div className="w-full text-Gray09 BBody16">
                    <div>💌 {ArtistData?.profileName}님이 보낸 프로필</div>
                    {/* <ArrowDown /> */}
                  </div>
                  <div className="text-Gray09 break-words whitespace-break-spaces RBody14 mt-4">
                    {ArtistData?.description}
                  </div>
                </div>
              </div>
            )}
            <div className="w-full">
              <ArtistInfoCard
                artistName={ArtistData?.profileName!}
                artistImageUrl={ArtistData?.thumbnailUrl!}
                jobs={ArtistData?.jobs!}
                birth={ArtistData?.birthDate!}
                height={ArtistData?.basicInfo.height!}
                weight={ArtistData?.basicInfo.weight!}
                instagram={ArtistData?.snsInfo.instagram!}
                youtube={ArtistData?.snsInfo.youtube!}
                isSharedPage
              />
              <div className="border-b border-Gray-3 mt-4">
                <div className="px-5 flex items-start gap-4 BCaption14">
                  {profileTab.map((item) => {
                    return (
                      <div key={item.id}
                        onClick={() => { setTabId(item.id) }}
                        className={`pb-4 cursor-pointer px-1 ${item.id === tabId ? "border-b-2 border-Gray09 text-Gray09" : "border-none text-Gray04"}`}>
                        {item.title}
                      </div>
                    )
                  })}
                </div>
              </div>
              {tabId === 0 ? (
                <div>
                  <ArtistAttachments
                    attachments={ArtistData?.attachments!}
                    videoLinks={ArtistData?.videoLinks!} />
                  <ArtistDesc
                    hobbies={ArtistData?.hobbies!}
                    address={ArtistData?.basicInfo.address.sido!}
                    education={ArtistData?.basicInfo.education!}
                    agency={ArtistData?.basicInfo.agency!}
                    introduce={ArtistData?.introduce!}
                  />
                  {ArtistData?.filmographies?.length! > 0 &&
                    <ArtistFilmographies filmographies={ArtistData?.filmographies!} />
                  }
                </div>
              ) : (
                <div />
              )}
            </div>
            {copyModal && (
              <Modal
                onClose={() => {
                  setProfileId(null);
                  setCopyModal(false);
                }}
                title="공유하기"
                subTitle="공유된 링크는 30일 동안 유효해요."
                closeButton
              >
                <Button
                  onClick={() => {
                    handleCopyClipBoard(
                      `${process.env.REACT_APP_URL}/artist/share?value=${link
                        ?.data.value!}&type=PROFILE`
                    );
                    setCopyModal(false);
                  }}
                  className="w-full border border-Gray03 text-Gray05"
                  text={"링크 복사하기"}
                ></Button>
              </Modal>
            )}
            {feedback && (
              <BottomPopup
                onClose={() => {
                  setFeedback(false);
                }}
              >
                <div className="BBody20">보낸 프로필 피드백</div>
                <div className="my-4 flex items-center justify-between">
                  <div className="flex items-center gap-1">
                    <div className="RBody16 text-Gray09">별점</div>
                    <div className="BBody16 text-Blue04">
                      {ArtistData?.rating ? ArtistData?.rating : 0}/5
                    </div>
                  </div>
                  <div className="flex items-center gap-1">
                    {readRatingStartValue()}
                  </div>
                </div>
                {ArtistData?.comments && (
                  <div className="p-4 bg-Gray01 max-h-64 rounded-md RBody16 break-words overflow-y-auto whitespace-break-spaces">
                    {ArtistData.comments}
                  </div>
                )}
                <ButtonBackground>
                  <LineButton
                    onClick={() => {
                      setFeedback(false);
                    }}
                    variant="normal"
                    size="B"
                    text="닫기"
                  />
                </ButtonBackground>
              </BottomPopup>
            )}
            {sendFeedback && (
              <BottomPopup
                onClose={() => {
                  setSendFeedback(false);
                  setFeedbackScore(0);
                }}
              >
                <div className="BBody20">프로필 피드백</div>
                <div className="overflow-y-scroll">

                  <div className="flex items-start gap-10 mt-4 mb-7">
                    <div>
                      <div className="flex items-center gap-1">
                        <div className="RBody16 text-Gray09">별점</div>
                        <span className="MCaption14 text-Blue04">(필수)</span>
                        <div className="BBody16 text-Blue04">
                          {feedbackScore ? feedbackScore : 0}/5
                        </div>
                      </div>
                      <div className="RCaption12 text-Gray05">아티스트에게 전달되지 않아요</div>
                    </div>
                    <div className="flex items-center gap-1">
                      {ratingStartValue()}
                    </div>
                  </div>


                  <div className="RBody16 mb-4">
                    이런 점이 좋아요🙂 / 아쉬워요😔 <span className="MCaption14 text-Blue04">(필수)</span>
                  </div>
                  <Textarea
                    placeholder={`${ArtistData?.profileName}님이 도움을 받을 수 있도록 좋은 점, 보완할 점 등을 상세하게 적어주세요. (50자 이상)`}
                    maxlength={2000}
                    onCopy={(e) => {
                      e.preventDefault();
                      e.clipboardData.setData("Text", "복사 불가능!");
                    }}
                    onCut={(e) => {
                      e.preventDefault();
                      e.clipboardData.setData("Text", "잘라내기 불가능!");
                    }}
                    onPaste={(e) => e.preventDefault()}
                    onChange={(e) => {
                      setReview(e.target.value);
                    }}
                    value={review}
                    rows={7}
                  />
                  <div onClick={() => {
                    setWarning(warning => !warning)
                  }} className="flex items-center justify-between mb-4">
                    <div className="BBody16 text-Gray09 ">
                      피드백 작성 유의사항
                    </div>
                    <Arrow className={`transform ${warning ? "rotate-180" : ""}`} />
                  </div>
                  {warning &&
                    <div className=" transition-all">
                      <ol className="list-disc list-inside RBody14 text-Gray05">
                        <li>
                          작성하신 프로필 피드백은 프로필 보낸 아티스트 회원에게 공개됩니다.
                        </li>
                        <li>
                          한 번 작성한 프로필 피드백은 수정/삭제할 수 없으니 신중히 적어주세요.
                        </li>
                        <li>
                          프로필 피드백은 프로필을 받은 후 30일 이내 작성 가능하며, 작성 시 포인트가 지급됩니다. (자세한 사항은 고객센터에 문의 부탁드립니다)
                        </li>
                        <li>
                          작성된 피드백 내용이 아래에 해당할 경우 포인트가 미지급될 수 있으며, 위반 내역의 심각성과 횟수에 따라 피드백 작성 제한 및 포인트 회수 조치가 진행될 수 있습니다.
                          <ol className="list-[lower-alpha] pl-6">
                            <li>부적절하거나 타인에게 불쾌감을 줄 수 있는 제안 (비속어 또는 부적절한 표현, 특정 대상에 대한 차별/혐오적 표현, 선정적이거나 폭력적인 내용, 문자 및 기호의 단순 나열 또는 반복된 내용 등)</li>
                            <li>서비스와 전혀 무관한 내용</li>
                            <li>기타 관계 법령, 서비스 이용 약관에 위배되는 피드백</li>
                          </ol>
                          <ol className="pl-6 list-disc">
                            <li>제한 조치에 대하여 이의가 있는 경우 고객센터를 통해 이의를 제기할 수 있습니다.</li>
                          </ol>
                        </li>
                      </ol>
                    </div>
                  }
                </div>
                <ButtonBackground>
                  <Button
                    onClick={() => {
                      submitFeedback();
                    }}
                    disabled={isButtonDisabled}
                    className="w-full bg-Blue04 text-Gray00 mt-4"
                    text={"피드백 보내기"}
                  />
                </ButtonBackground>
              </BottomPopup>
            )}
            {!isHiddenStatus && (
              <BottomButtonPopup
                onClose={() => { }}
              >
                <div className="flex flex-col justify-center">
                  {ArtistData?.rating ?
                    <div
                      onClick={() => (setFeedback(true))}
                      className="flex flex-col h-full flex-1 justify-center items-center MBody14 cursor-pointer text-Gray00 pt-2">
                      <Feedback />
                      <div>보낸 피드백 확인하기</div>
                    </div>
                    :
                    <div
                      onClick={() => (setSendFeedback(true))}
                      className="flex flex-col h-full flex-1 justify-center items-center MBody14 cursor-pointer text-Gray00 pt-2">
                      <SendFeedback />
                      <div>피드백 보내기</div>
                    </div>
                  }
                </div>
              </BottomButtonPopup>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ArtistReceived;
