import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import requestToCastingVote from "./api";
import { profileScrapRequest, scrapRequest } from "types/scrap";

// 프로필 스크랩
const scrapProfile = async (profileScrapRequest: profileScrapRequest) => {
  return await requestToCastingVote
    .post(`favorites/profile`, profileScrapRequest)
    .then((res) => res.data);
};

const useScrapProfile = () => {
  return useMutation({
    mutationFn: scrapProfile,
  });
};

// 프로필 스크랩 삭제
const deleteScrapProfile = async (favoriteId: number) => {
  return await requestToCastingVote
    .delete(`favorites/profile`, { data: { favoriteId } })
    .then((res) => res.data);
};

const useDeleteScrapProfile = () => {
  return useMutation({
    mutationFn: deleteScrapProfile,
  });
};

// 작품 스크랩
const scrapProduction = async (productionId: number) => {
  return await requestToCastingVote
    .post(`favorites/production`, productionId)
    .then((res) => res.data);
};

const useScrapProduction = () => {
  return useMutation({
    mutationFn: scrapProduction,
  });
};

// 작품 스크랩 삭제
const deleteScrapProduction = async (favoriteId: number) => {
  return await requestToCastingVote
    .delete(`favorites/production`, { data: favoriteId })
    .then((res) => res.data);
};

const useDeleteScrapProduction = () => {
  return useMutation({
    mutationFn: deleteScrapProduction,
  });
};

// 공고 스크랩
const scrapCastingcall = async (castingCallId: number) => {
  return await requestToCastingVote
    .post(`favorites/castingcall`, { castingCallId })
    .then((res) => res.data);
};

const useScrapCastingcall = () => {
  return useMutation({
    mutationFn: scrapCastingcall,
  });
};

// 공고 스크랩 삭제
const deleteScrapCastingcall = async (favoriteId: number | null) => {
  return await requestToCastingVote
    .delete(`favorites/castingcall`, { data: { favoriteId } })
    .then((res) => res.data);
};

const useDeleteScrapCastingcall = () => {
  return useMutation({
    mutationFn: deleteScrapCastingcall,
  });
};

// 프로필 스크랩 조회
const getProfileScrap = async (profileScrapRequest: profileScrapRequest) => {
  return await requestToCastingVote
    .get(`favorites/profile`, { params: profileScrapRequest })
    .then((res) => res.data);
};

const useProfileScrap = (
  profileScrapRequest: profileScrapRequest,
  enabled: boolean
) => {
  return useInfiniteQuery({
    queryKey: ["favoriteProfiles", profileScrapRequest],
    queryFn: ({ pageParam = 0 }) =>
      getProfileScrap({ ...profileScrapRequest, page: pageParam }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
    enabled,
  });
};

// 작품 스크랩 조회
const getProductionScrap = async (scrapRequest: scrapRequest) => {
  return await requestToCastingVote
    .get(`favorites/production`, { params: scrapRequest })
    .then((res) => res.data);
};

const useProductionScrap = (scrapRequest: scrapRequest, enabled: boolean) => {
  return useInfiniteQuery({
    queryKey: ["favoriteProductions", scrapRequest],
    queryFn: ({ pageParam = 0 }) =>
      getProductionScrap({ ...scrapRequest, page: pageParam }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
    enabled,
  });
};

// 공고 스크랩 조회
const getCastingcallScrap = async (scrapRequest: scrapRequest) => {
  return await requestToCastingVote
    .get(`favorites/castingcall`, { params: scrapRequest })
    .then((res) => res.data);
};

const useCastingcallScrap = (scrapRequest: scrapRequest, enabled: boolean) => {
  return useInfiniteQuery({
    queryKey: ["favoriteCastingcall", scrapRequest],
    queryFn: ({ pageParam = 0 }) =>
      getCastingcallScrap({ ...scrapRequest, page: pageParam }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
    enabled,
  });
};

export {
  useScrapProfile,
  useDeleteScrapProfile,
  useScrapProduction,
  useDeleteScrapProduction,
  useScrapCastingcall,
  useDeleteScrapCastingcall,
  useProfileScrap,
  useProductionScrap,
  useCastingcallScrap,
};
