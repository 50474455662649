import React from 'react';
import { useNavigate } from 'react-router';
import { HAS_PROFILE } from 'utils/onRoleStatus';

const Manager = () => {
    const navigate = useNavigate();
    const hasProfile = HAS_PROFILE();

    const manager = [
        { id: 0, title: "아티스트 관리", path: hasProfile ? "/manage/artist" : "/staff/my", },
        { id: 1, title: "보낸 프로필", path: "/artist/send" },
        { id: 2, title: "받은 제안", path: "/artist/suggest" },
    ];

    const staff = [
        { id: 0, title: "내 프로필", path: "/staff/my" },
        { id: 1, title: "내 캐스팅", path: hasProfile ? "/staff/casting/my" : "/staff/my", },
        { id: 2, title: "참여 캐스팅", path: "/staff/reference" },
        { id: 3, title: "받은 프로필", path: "/staff/received" },
        { id: 4, title: "보낸 제안", path: "/staff/suggest" },
        { id: 5, title: "보낸 피드백 현황", path: "/staff/feedback" },
        { id: 6, title: "스크랩", path: "/scrap" },
    ];

    return (
        <div className='flex flex-col gap-4'>
            <div className='flex flex-col gap-4 px-5'>
                {manager.map((item) => {
                    return (
                        <div
                            onClick={() => {
                                navigate(item.path);
                            }}
                            key={item.id}
                            className='cursor-pointer'>
                            {item.title}
                        </div>
                    )
                })}
            </div>
            <hr className="w-full h-px bg-Gray01" />
            <div className='flex flex-col gap-4 px-5'>
                {staff.map((item) => {
                    return (
                        <div
                            onClick={() => {
                                navigate(item.path);
                            }}
                            key={item.id}
                            className='cursor-pointer'>
                            {item.title}
                        </div>
                    )
                })}
            </div>
            <hr className="w-full h-px bg-Gray01" />
            <div className='px-5'>
                <div
                    onClick={() => {
                        navigate("/profile-delivery");
                    }}
                    className='cursor-pointer'>
                    딜리버리
                </div>
            </div>
            <hr className="w-full h-px bg-Gray01" />
            <div className='px-5'>
                <div
                    onClick={() => {
                        window.open(
                            "https://daffy-periwinkle-38f.notion.site/1344e358e81b80f9bbd3d7c0a76f3398?pvs=4",
                            "_blank",
                            "noopener, noreferrer"
                        );
                    }}
                    className="cursor-pointer"
                >
                    고객센터
                </div>
            </div>
        </div>
    );
};

export default Manager;