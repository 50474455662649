import React from 'react';

import { useNavigate } from 'react-router';

import Thumbnail from 'assets/thumbnail.png'

interface ProductionCardType {
    thumbnailUrl: string;
    format: string;
    productionId: number;
    companyName: string;
    title: string;
    onDeleteClick?: () => void;
}

const ProductionCard = ({
    thumbnailUrl,
    format,
    productionId,
    title,
    onDeleteClick
}: ProductionCardType) => {


    const navigate = useNavigate();
    const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

    return (
        <>
            <div className='py-5 border-b border-Gray02 px-5'>
                <div className='flex items-center gap-4'>
                    <img className='w-12 h-12 rounded object-cover' src={thumbnailUrl ? MEDIA_URL + thumbnailUrl : Thumbnail} alt="작품 이미지" />
                    <div className='flex flex-1 flex-col w-full'>
                        <div className='flex items-center justify-between'>
                            <div className='MCaption12 text-Gray05'>{format}</div>
                            <div className='flex items-center BCaption12 text-Gray05 gap-4'>
                                <div
                                    onClick={() => {
                                        navigate(`/staff/product/my/${productionId}`);
                                    }}
                                    className='cursor-pointer'>작품 보기</div>
                                <div onClick={() => {
                                    navigate(`/staff/post/edit/${productionId}`);
                                }} className='cursor-pointer'>수정</div>
                                <div onClick={onDeleteClick} className='text-Error cursor-pointer'>삭제</div>
                            </div>
                        </div>
                        <div onClick={() => {
                            navigate(`/staff/castingcall/${productionId}`)
                        }} className='MBody18 cursor-pointer'>{title}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductionCard;