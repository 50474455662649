import React, { useEffect, useMemo, useState } from "react";

import SearchBox from "components/SearchBox";
import StaffCard from "components/StaffCard";
import Checkbox from "components/Checkbox";
import Header from "components/Header";

import { useInView } from "react-intersection-observer";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";

import { useStaffProfiles } from "apis/profile";
import FullPage from "stores/Modal/fullPage";
import useLocalStorage from "use-local-storage";
import { useScroll } from "hooks/useScroll";

type SearchType = "name" | "filmoTitle" | "agency";

const StaffList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const LOCAL_STORAGE_KEY = "staffState";
  const navigationType = useNavigationType();
  const { closeFullPage } = FullPage();

  const [selectedType, setSelectedType] = useState<SearchType>("name");
  const [selectedJob, setSelectedJob] = useState("CASTING_DIRECTOR");
  const [sort, setSort] = useState("name,asc");
  const [isCasting, setIsCasting] = useState(false);
  const [searchValues, setSearchValues] = useState<Record<SearchType, string>>({
    name: "",
    filmoTitle: "",
    agency: "",
  });
  const [debouncedSearchValues, setDebouncedSearchValues] = useState(searchValues);
  const scrollKey = `scrollIndex${location.pathname}`;
  const [scrollY, setScrollY] = useLocalStorage(scrollKey, 0);

  const { y } = useScroll();

  useEffect(() => {
    if (scrollY !== 0 && navigationType === "POP") {
      setTimeout(() => {
        window.scrollTo(0, scrollY);
      }, 100);
    }
  }, [navigationType, scrollY]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValues(searchValues);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [searchValues]);

  const { data, fetchNextPage, hasNextPage } =
    useStaffProfiles({
      role: selectedJob,
      sort: sort,
      name: debouncedSearchValues.name,
      filmographyTitle: debouncedSearchValues.filmoTitle,
      agency: debouncedSearchValues.agency,
      isCasting: isCasting,
    });

  const {
    ref,
    inView,
  } = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    if (navigationType === "POP") {
      const savedState = sessionStorage.getItem(LOCAL_STORAGE_KEY);
      if (savedState) {
        const parsedState = JSON.parse(savedState);
        setSelectedType(parsedState.selectedType || "name");
        setSelectedJob(parsedState.selectedJob || "CASTING_DIRECTOR");
        setSort(parsedState.sort || "name,asc");
        setIsCasting(parsedState.isCasting || false);
        setSearchValues(
          parsedState.searchValues || {
            name: "",
            filmoTitle: "",
            agency: "",
          }
        );
      }
    } else {
      sessionStorage.removeItem(LOCAL_STORAGE_KEY);
    }
  }, [navigationType]);

  useEffect(() => {
    const stateToSave = {
      selectedType,
      selectedJob,
      sort,
      isCasting,
      searchValues,
    };
    sessionStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(stateToSave));
  }, [selectedType, selectedJob, sort, isCasting, searchValues]);

  const job = [
    { id: 0, title: "캐스팅디렉터", role: "CASTING_DIRECTOR" },
    { id: 1, title: "매니저", role: "MANAGER" },
    { id: 2, title: "연출", role: "DIRECTOR" },
    { id: 3, title: "제작", role: "PRODUCER" },
    { id: 4, title: "광고", role: "AGENCY" },
    { id: 5, title: "작가", role: "WRITER" },
  ];

  const options = [
    { id: 0, title: "이름", value: "name" },
    { id: 1, title: "작품", value: "filmoTitle" },
    { id: 2, title: "소속사", value: "agency" },
  ];

  const onTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSearchValues({
      name: "",
      filmoTitle: "",
      agency: "",
    });

    setSelectedType(value as SearchType);
  };

  const allLists = useMemo(
    () => (data ? data?.pages?.flatMap((page) => page.data.content) : []),
    [data]
  );

  return (
    <>
      <Header title="STAFF" />
      <div className="relative mt-2 w-full grid grid-cols-3 border-y-[0.5px] border-Gray03">
        {job.map((item, index) => {
          return (
            <div
              onClick={() => {
                setSelectedJob(item.role);
                setIsCasting(false);
              }}
              key={item.id}
              className={`h-12 flex cursor-pointer items-center justify-center nonDragable hover:bg-Gray01/50 ${selectedJob === item.role
                ? "text-Gray09 bg-Gray01/50 BBody14"
                : "text-Gray05 MBody14"
                } ${index % 3 === 0
                  ? "border-l-0"
                  : "border-l-[0.5px] border-Gray03"
                } ${index >= job.length - 3 ? "border-b-0" : "border-b-[0.5px]"}`}
            >
              {item.title}
            </div>
          );
        })}
      </div>

      <div className="pb-24 p-5">
        <div className="flex items-center gap-4">
          <SearchBox
            onDropDownChange={onTypeChange}
            onChange={(e) => {
              const value = e.target.value;
              setSearchValues({
                ...searchValues,
                [selectedType]: value,
              });
            }}
            value={searchValues[selectedType]}
            placeholder="이름, 작품, 소속사"
          >
            {options.map((item) => {
              return (
                <option key={item.id} value={item.value}>
                  {item.title}
                </option>
              );
            })}
          </SearchBox>
        </div>
        <div>
          <div className="flex items-center my-4 justify-between">
            <select
              onChange={(e) => {
                setSort(e.target.value);
              }}
              value={sort}
            >
              <option value="name,asc">이름순 ↑</option>
              <option value="name,desc">이름순 ↓</option>
              <option value="updatedAt,asc">최신순 ↑</option>
              <option value="updatedAt,desc">최신순 ↓</option>
            </select>
            <Checkbox
              id="nowCasting"
              onChange={(e) => {
                setIsCasting(e.target.checked);
              }}
              checked={isCasting}
              title={"캐스팅중"}
            />
          </div>
        </div>
        {allLists.length === 0 ? (
          <div className="mt-36 items-center text-center">
            <div className="text-Gray05 RBody14">모집중인 캐스팅이 없어요.</div>
          </div>
        ) : (
          <div>
            <div className="flex flex-col gap-4">
              {allLists?.map((item) => {
                return (
                  <StaffCard
                    key={item?.profileId}
                    isCasting={item?.isCasting}
                    isPending={item?.status?.code === "PENDING"}
                    image={item?.thumbnailUrl}
                    role={item?.role.label}
                    favoriteId={item?.favoriteProfileId}
                    isBookmark={item.isFavorite}
                    profileId={item?.profileId}
                    agency={item?.agency}
                    name={item?.profileName}
                    onClick={() => {
                      closeFullPage()
                      setScrollY(y)
                      navigate(`${item?.profileId}`, {
                        state: { role: item?.role?.code },
                      })
                    }}
                  />
                );
              })}
              {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default StaffList;
