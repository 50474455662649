import React, { useEffect, useMemo, useState } from "react";

import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import useLocalStorage from "use-local-storage";
import { useScroll } from "hooks/useScroll";
import ProductionCard from "./MyCasting/_components/ProductionCard";
import { useMyProductions } from "apis/my";
import { useDeleteProduction } from "apis/production";
import { Toast } from "hooks/useToast";

import HeaderWithBackButton from "components/HeaderWithBackButton";
import BottomPopup from "components/BottomPopup";
import ButtonBackground from "components/ButtonBackground";
import { FillButton } from "components/FillButton";
import { TintButton } from "components/TintButton";

const StaffMyCasting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();

  const [deletePop, setDeletePop] = useState<{ id: number | null; title: string; }>({ id: null, title: "" });

  const scrollKey = `scrollIndex${location.pathname}`;

  const [scrollY, setScrollY] = useLocalStorage(scrollKey, 0);

  const { y } = useScroll();

  useEffect(() => {
    setScrollY(y);
  }, [y]);

  useEffect(() => {
    if (scrollY !== 0 && navigationType === "POP") {
      setTimeout(() => {
        window.scrollTo(0, scrollY);
      }, 100);
    }
  }, [navigationType, scrollY]);

  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const {
    data,
    fetchNextPage,
    hasNextPage,
    refetch
  } = useMyProductions({ isActiveProfile: true });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  const allLists = useMemo(
    () => (data ? data?.pages?.flatMap((page) => page.data.content) : []),
    [data]
  );

  const { mutate } = useDeleteProduction();

  const deleteProduct = () => {
    mutate(deletePop?.id, {
      onSuccess: () => {
        Toast.success("작품이 삭제되었어요.")
        setDeletePop({
          id: null,
          title: ""
        })
        refetch()
      }, onSettled: () => {
        setDeletePop({
          id: null,
          title: ""
        })
      }
    })
  }

  return (
    <div>
      <HeaderWithBackButton title="내 캐스팅">
        <div
          onClick={() => {
            navigate("/staff/post");
          }}
          className={`BCaption14 text-Blue04 flex items-center gap-2 cursor-pointer`}
        >
          <div>작품등록</div>
        </div>
      </HeaderWithBackButton>
      <div className="pb-24">
        <div className="flex flex-col">
          {allLists.map((prodItem: myProduct) => {
            return (
              <ProductionCard
                key={prodItem.productionId}
                thumbnailUrl={prodItem.thumbnailUrl}
                format={prodItem.format.label}
                productionId={prodItem.productionId}
                companyName={prodItem.companyName}
                onDeleteClick={() => {
                  setDeletePop({
                    id: prodItem.productionId,
                    title: prodItem.title
                  })
                }}
                title={prodItem.title} />
            );
          })}
          {hasNextPage && <div className="h-5 bg-transparent" ref={ref} />}
        </div>
      </div>


      {deletePop.id &&
        <BottomPopup onClose={() => {
          setDeletePop({
            id: null,
            title: ""
          })
        }}>
          <div className='BBody20'>
            {deletePop.title} 작품을 삭제할까요?
          </div>
          <div className='MBody14 text-Gray05 mt-4'>
            작품 삭제 후에는 되돌릴 수 없어요.<br />
            캐스팅을 마감했는지 확인해 주세요.
          </div>
          <ButtonBackground>
            <FillButton
              onClick={() => {
                setDeletePop({
                  id: null,
                  title: ""
                })
              }}
              variant="Secondary"
              size="L"
              className="w-full"
              text='닫기'
            />
            <TintButton
              onClick={() => {
                deleteProduct()
              }}
              variant="delete"
              size="L"
              className="w-full"
              text="삭제하기"
            />
          </ButtonBackground>
        </BottomPopup>
      }

    </div >
  );
};

export default StaffMyCasting;


interface myProduct {
  productionId: number;
  title: string;
  format: {
    code: string;
    label: string;
  },
  companyName: string;
  thumbnailUrl: string;
}