import React, { useEffect, useRef, useState } from "react";

import KakaoLogin from "../assets/kakaoLogin.png";

import Input from "components/Input";
import { useNavigate, useSearchParams } from "react-router-dom";
import Checkbox from "components/Checkbox";
import BottomPopup from "components/BottomPopup";
import { encryptData } from "utils/crypto";
import { Toast } from "hooks/useToast";
import requestToCastingVote from "utils/requestToCastingVote";
import Header from "components/Header";
import ButtonBackground from "components/ButtonBackground";
import { FillButton } from "components/FillButton";
import { LineButton } from "components/LineButton";
import { useLogin } from "apis/user";
import useLoginInfo from "stores/loginInfo";

const Login = () => {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const { setIsLogin, setLoginInfo } = useLoginInfo();


  const [popup, setPopup] = useState<Window | null>(null);
  const reqKmcisForm = useRef<HTMLFormElement | null>(null);
  const [agreeTermModal, setAgreeTermModal] = useState(false);
  const [checkItems, setCheckItems] = useState<number[]>([]);
  const [kmcCert, setKmcCert] = useState({
    cert: "",
    certNum: "",
  });

  const [searchParams] = useSearchParams();
  const code = Number(searchParams.get("code"));

  useEffect(() => {
    if (code === 4001) {
      Toast.error("로그인이 필요합니다.");
    } else if (code === 4005) {
      Toast.error("카카오 로그인 실패했습니다.");
    }
  }, [code]);

  const [loginData, setLoginData] = useState({
    userId: "",
    password: "",
    remember: false,
  });
  const [selectAgree, setSelectAgree] = useState({
    termsOfService: false,
    termsOfServiceAt: "",
    privacyPolicy: false,
    privacyPolicyAt: "",
    thirdPartySharing: false,
    thirdPartySharingAt: "",
    marketingOptIn: false,
    marketingOptInAt: "",
  });

  const checkList = [
    {
      id: 0,
      title: "서비스 이용약관 동의 (필수)",
      type: "termsOfService",
      nav: "/terms",
    },
    {
      id: 1,
      title: "개인정보 수집 및 이용 동의 (필수)",
      type: "privacyPolicy",
      nav: "/private",
    },
    {
      id: 2,
      title: "제 3자 정보 제공 동의 (필수)",
      type: "thirdPartySharing",
      nav: "/third-party-sharing",
    },
    {
      id: 3,
      title: "마케팅 수신 동의 (선택)",
      type: "marketingOptIn",
      nav: "/marketing-optin",
    },
  ];

  const requiredValues = [0, 1, 2];

  const isFormValid = requiredValues.every((val) => checkItems.includes(val));

  const checkItemHandler = (id: number, isChecked: boolean, type: string) => {
    const formattedDate = new Date().toISOString().slice(0, 19);
    if (isChecked) {
      setCheckItems((prev) => [...prev, id]);
      setSelectAgree((pre) => ({
        ...pre,
        [type]: true,
        [`${type}At`]: formattedDate,
      }));
    } else {
      setCheckItems(checkItems.filter((item) => item !== id));
      setSelectAgree((pre) => ({
        ...pre,
        [type]: false,
        [`${type}At`]: "",
      }));
    }
  };

  const allCheckedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedDate = new Date().toISOString().slice(0, 19);
    if (e.target.checked) {
      setCheckItems(checkList.map((item) => item.id));
      setSelectAgree((pre) => ({
        ...pre,
        termsOfService: true,
        termsOfServiceAt: formattedDate,
        privacyPolicy: true,
        privacyPolicyAt: formattedDate,
        thirdPartySharing: true,
        thirdPartySharingAt: formattedDate,
        marketingOptIn: true,
        marketingOptInAt: formattedDate,
      }));
    } else {
      setCheckItems([]);
      setSelectAgree({
        termsOfService: false,
        termsOfServiceAt: "",
        privacyPolicy: false,
        privacyPolicyAt: "",
        thirdPartySharing: false,
        thirdPartySharingAt: "",
        marketingOptIn: false,
        marketingOptInAt: "",
      });
    }
  };

  const data = {
    userId: loginData.userId.trim(),
    password: encryptData(loginData.password).trim(),
    remember: loginData.remember,
  };

  const { mutate, isPending } = useLogin();

  const handleLogin = () => {
    mutate(data, {
      onSuccess: (data) => {
        successLogin(data);
      },
    },
    );
  };

  const successLogin = (data: any) => {
    setIsLogin(true);
    setLoginInfo()
    navigate("/", { replace: true });
  };

  const OpenKMCISWindow = () => {
    const UserAgent = navigator.userAgent;
    const width = 500;
    const height = 550;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;

    const KMCIS_URL = "https://www.kmcert.com/kmcis/web/kmcisReq.jsp";
    if (reqKmcisForm.current) {
      if (
        UserAgent.match(
          /iPhone|iPad|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i
        ) != null ||
        UserAgent.match(/LG|SAMSUNG|Samsung/) != null
      ) {
        reqKmcisForm.current.target = "";
      } else {
        // PC
        const kmcisPopup = window.open(
          "",
          "KMCISWindow",
          `width=${width},height=${height},left=${left},top=${top},resizable=0,scrollbars=no,status=0,titlebar=0,toolbar=0`
        );
        if (kmcisPopup === null) {
          alert("팝업이 차단되어 있습니다. 팝업을 허용해주세요.");
          return;
        }
        setPopup(kmcisPopup);
        reqKmcisForm.current.target = "KMCISWindow";
      }
      reqKmcisForm.current.action = KMCIS_URL;
      reqKmcisForm.current.submit();
    }
  };

  const handleKmcCert = async () => {
    const result = await requestToCastingVote({
      method: "GET",
      url: "kmc/cert",
    });
    const { cert, certNum } = result.data.data;
    setKmcCert({ cert, certNum });
  };

  useEffect(() => {
    if (kmcCert.cert && kmcCert.certNum) {
      OpenKMCISWindow();
    }
  }, [kmcCert]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== `${process.env.REACT_APP_URL}`) return;
      if (event.data.certNum) {
        navigate("/signup", { state: event.data });
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleKakaoLongin = () => {
    // window.location.href = `http://localhost:8080/api/oauth2/authorization/kakao`;
    window.location.href = `${API_URL}/oauth2/authorization/kakao`;
  };

  return (
    <>
      <Header />
      <div className="flex px-5 flex-col gap-10 pb-24">
        <form ref={reqKmcisForm} name="reqKmcisForm" method="post" action="#">
          <input type="hidden" name="tr_cert" value={kmcCert.cert} />
          <input
            type="hidden"
            name="tr_url"
            value={`${process.env.REACT_APP_URL}/kmc/callback`}
          />
          <input type="hidden" name="tr_ver" value="V2" />
        </form>
        {agreeTermModal && (
          <BottomPopup onClose={() => setAgreeTermModal(false)}>
            <div>
              <div className="BHead24 mb-4">
                회원가입 전에
                <br />
                약관 동의가 필요해요.
              </div>
              <div className="flex flex-col gap-4">
                {checkList.map((item) => {
                  return (
                    <Checkbox
                      look
                      onClick={() => {
                        window.open(
                          `${item.nav}`,
                          "_blank",
                          "noopener, noreferrer"
                        );
                      }}
                      id={item.title}
                      checked={checkItems.includes(item.id) ? true : false}
                      onChange={(e) => {
                        checkItemHandler(item.id, e.target.checked, item.type);
                      }}
                      key={item.id}
                      title={item.title}
                    />
                  );
                })}
              </div>
              <hr className="w-full h-px my-4 bg-Gray03" />
              <Checkbox
                id="전체선택"
                checked={checkItems.length === checkList.length ? true : false}
                onChange={allCheckedHandler}
                className=""
                title="전체 동의하기"
              />
              <ButtonBackground>
                <FillButton
                  variant="Membership"
                  size="B"
                  text="동의하고 본인인증하기"
                  disabled={!isFormValid}
                  onClick={() => {
                    const agree = selectAgree;
                    const agreeJson = JSON.stringify(agree);
                    localStorage.setItem("agree", agreeJson);
                    handleKmcCert();
                  }}
                />
              </ButtonBackground>
            </div>
          </BottomPopup>
        )}
        <div className="text-center BBody20 mt-10 mx-auto">
          캐스팅을 향한 빠른 길,
          <br />
          캐스팅보트를 시작하세요 🙌
        </div>
        <div className="flex flex-col gap-4">
          <Input
            onChange={(e) => {
              setLoginData({ ...loginData, userId: e.target.value });
            }}
            value={loginData.userId}
            placeholder="아이디"
            className=" w-full"
            type="text"
          />
          <Input
            onChange={(e) => {
              setLoginData({ ...loginData, password: e.target.value });
            }}
            value={loginData.password}
            placeholder="비밀번호"
            className=" w-full"
            type="password"
          />
          <Checkbox
            onChange={(e) => {
              setLoginData({ ...loginData, remember: e.target.checked });
            }}
            id="login"
            checked={loginData?.remember}
            className="RBody14 text-Gray05"
            title="로그인 유지"
          />
        </div>
        <div className="flex text-center flex-col gap-2 max-w-[520px]">
          <FillButton
            isLoading={isPending}
            variant="Membership"
            size="L"
            disabled={!loginData.password || !loginData.userId || isPending}
            onClick={() => {
              handleLogin();
            }}
            text="로그인"
          />
          <LineButton
            onClick={() => {
              setCheckItems([]);
              setAgreeTermModal(true);
            }}
            variant="normal"
            text="회원가입"
            size="L"
          />

          <div className="flex items-center mx-auto gap-4 mt-6 RBody14 text-Gray05">
            <div
              onClick={() => {
                navigate("/find/verify", { state: "ID" });
              }}
              className="cursor-pointer"
            >
              아이디 찾기
            </div>
            <div>|</div>
            <div
              onClick={() => {
                navigate("/find/verify", { state: "PASSWORD" });
              }}
              className="cursor-pointer"
            >
              비밀번호 찾기
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4 mx-auto w-full">
          <div className="flex items-center justify-between mx-auto gap-[41px]">
            <hr className="flex-1 h-px bg-Gray03" />
            <div className="text-sm MBody14 text-Gray05">SNS 간편 로그인</div>
            <hr className="flex-1 h-px bg-Gray03" />
          </div>
          <div className="text-sm RBody14 cursor-pointer grid gap-2 text-Gray09 items-center mx-auto">
            <img src={KakaoLogin} alt="snsLogin" onClick={handleKakaoLongin} />
            <div>카카오톡</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
