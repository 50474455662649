import { usePaymentHistory } from 'apis/payment';
import { useProfileDeliveryHistory } from 'apis/profileDelivery';
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import React, { useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

const DeliveryHistory = () => {

    const { ref, inView } = useInView({
        threshold: 0.5,
    });

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = usePaymentHistory({ paymentCategory: "OFFLINE_DELIVERY" });

    const allLists = useMemo(
        () => (data ? data?.pages?.flatMap((page) => page.data.content) : []),
        [data]
    );

    return (
        <div>
            <HeaderWithBackButton title={"신청내역"} />

            {allLists.length === 0 ? (
                <div className="mt-36 items-center text-center">
                    <div className="text-Gray05 RBody14">신청내역이 없어요.</div>
                </div>
            ) : (
                <div className='px-5'>
                    {allLists.map((item) => {
                        return (
                            <div key={item.orderId} className='mt-5 border-b border-Gray03 pb-4 last:border-none'>
                                <div className='RCaption14 text-Gray05'>
                                    {item?.paymentDate?.split("T")[0]}
                                </div>
                                <div className='flex items-center justify-between mt-2'>
                                    <div className='MBody16 text-Gray09'>딜리버리 {item?.paymentStatus?.label}</div>
                                    <div className='BBody16 text-Gray09'>{item?.orderAmount.toLocaleString()}원</div>
                                </div>
                            </div>
                        );
                    })}
                    {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
                </div>
            )}


        </div>
    );
};

export default DeliveryHistory;