import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import requestToCastingVote from "./api";
import {
  cancelPaymentRequest,
  createPaymentRequest,
  membershipHistoryRequest,
  membershipSubscribeRequest,
  paymentHistoryRequest,
  updateSubscribeCardRequest,
  createDeliveryPaymentRequest,
} from "types/payment";

// 웰컴 페이 멤버십 구독 결제
const createSubscribe = async (
  membershipSubscribeRequest: membershipSubscribeRequest
) => {
  return await requestToCastingVote
    .post(`payments/welcomepay/subscribe`, membershipSubscribeRequest)
    .then((res) => res.data);
};

const useCreateSubscribe = () => {
  return useMutation({
    mutationFn: createSubscribe,
  });
};

// 웰컴 페이 멤버십 구독 카드 수정
const updateSubscribeCard = async (
  updateSubscribeCardRequest: updateSubscribeCardRequest
) => {
  return await requestToCastingVote
    .patch(`payments/welcomepay/billing`, updateSubscribeCardRequest)
    .then((res) => res.data);
};

const useUpdateSubscribeCard = () => {
  return useMutation({
    mutationFn: updateSubscribeCard,
  });
};

// 웰컴 페이 멤버십 구독 해지
const deleteSubscribe = async () => {
  return await requestToCastingVote
    .delete(`payments/welcomepay/billing`)
    .then((res) => res.data);
};

const useDeleteSubscribe = () => {
  return useMutation({
    mutationFn: deleteSubscribe,
  });
};

// 멤버십 결제 이력
const getMembershipHistory = async (
  membershipHistoryRequest: membershipHistoryRequest
) => {
  return await requestToCastingVote
    .get(`membership/histories`, { params: membershipHistoryRequest })
    .then((res) => res.data);
};

const useMembershipHistory = (
  membershipHistoryRequest: membershipHistoryRequest
) => {
  return useInfiniteQuery({
    queryKey: ["membershipHistory", membershipHistoryRequest],
    queryFn: ({ pageParam = 0 }) =>
      getMembershipHistory({ ...membershipHistoryRequest, page: pageParam }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

// 포인트 결제 초기 데이터 생성
const createPayment = async (createPaymentRequest: createPaymentRequest) => {
  return await requestToCastingVote
    .post(`payments/welcomepay`, createPaymentRequest)
    .then((res) => res.data);
};

const useCreatePayment = () => {
  return useMutation({
    mutationFn: createPayment,
  });
};

// 결제 초기 데이터 생성(오프라인 딜리버리)
const createDeliveryPayment = async (
  createDeliveryPaymentRequest: createDeliveryPaymentRequest
) => {
  return await requestToCastingVote
    .post(`payments/welcomepay/delivery`, createDeliveryPaymentRequest)
    .then((res) => res.data);
};

const useCreateDeliveryPayment = () => {
  return useMutation({
    mutationFn: createDeliveryPayment,
  });
};

// 필름 결제 부분 취소
const cancelPayment = async (cancelPaymentRequest: cancelPaymentRequest) => {
  return await requestToCastingVote
    .post(`payments/welcomepay/cancel`, cancelPaymentRequest)
    .then((res) => res.data);
};

const useCancelPayment = () => {
  return useMutation({
    mutationFn: cancelPayment,
  });
};

// 포인트 결제 내역 조회(완료,취소)
const getPaymentHistory = async (
  paymentHistoryRequest: paymentHistoryRequest
) => {
  return await requestToCastingVote
    .get(`payments/completed-cancelled`, { params: paymentHistoryRequest })
    .then((res) => res.data);
};

const usePaymentHistory = (paymentHistoryRequest: paymentHistoryRequest) => {
  return useInfiniteQuery({
    queryKey: ["paymentHistory", paymentHistoryRequest],
    queryFn: ({ pageParam = 0 }) =>
      getPaymentHistory({ ...paymentHistoryRequest, page: pageParam }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

// 사용중인 멤버십
const getMyMembership = async () => {
  return await requestToCastingVote.get(`membership`).then((res) => res.data);
};

const useMyMembership = () => {
  return useQuery({
    queryKey: ["myMembership"],
    queryFn: () => getMyMembership(),
  });
};

// 멤버십 종류 조회
const getMembershipType = async (membershipTypeId: number) => {
  return await requestToCastingVote
    .get(`membershiptype`, { params: { membershipTypeId } })
    .then((res) => res.data);
};

const useMembershipType = (membershipTypeId: number) => {
  return useQuery({
    queryKey: ["membershipType", membershipTypeId],
    queryFn: () => getMembershipType(membershipTypeId),
  });
};

export {
  useCreateSubscribe,
  useUpdateSubscribeCard,
  useDeleteSubscribe,
  useMembershipHistory,
  useCreatePayment,
  useCreateDeliveryPayment,
  useCancelPayment,
  usePaymentHistory,
  useMyMembership,
  useMembershipType,
};
