import React, { useEffect } from 'react';

import HeaderWithBackButton from 'components/HeaderWithBackButton';
import { ReactComponent as DeliveryIcon } from 'assets/deliveryComplete.svg'
import { FillButton } from 'components/FillButton';
import { useNavigate, useSearchParams } from 'react-router';
import { Toast } from 'hooks/useToast';

const DeliveryComplete = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const message = searchParams?.get("message");

    useEffect(() => {
        Toast.success(message);
    }, [message]);

    return (
        <div className='pb-[135px]'>
            <HeaderWithBackButton setIsBackAction={() => {
                navigate("/more", { replace: true })
            }} title={'결제 완료'} />
            <div className='py-10 flex flex-col justify-center items-center gap-10'>
                <DeliveryIcon />
                <div className='text-center BBody16'>
                    <div>결제 후 신청 폼까지 제출해야</div>
                    <div>딜리버리 신청이 완료되니</div>
                    <div>꼭 아래의 신청 폼을 작성해 주세요:)</div>
                </div>
            </div>
            <hr className="w-full h-2 mb-4 bg-Gray01" />
            <div className="px-5 text-Gray09">
                <div className="RCaption12 mb-2">유의사항</div>
                <div className="flex flex-col gap-2">
                    <div>
                        <div className="MCaption12 ">구매안내</div>
                        <ol className="list-disc pl-4 RCaption12">
                            <li>mm월 dd일에 인쇄가 시작되고 mm월 dd일부터 딜리버리 시작되는 상품입니다.</li>
                            <li>결제 금액은 부가세(VAT)가 포함된 가격입니다.</li>
                            <li>미성년 회원의 결제는 원칙적으로 법정대리인의 명의 또는 동의 하에 이루어져야 하고, 법정대리인은 본인 동의 없이 체결된 자녀(미성년자)의 계약을 취소할 수 있습니다.</li>
                        </ol>
                    </div>
                    <div>
                        <div className="MCaption12">환불안내</div>
                        <ol className="list-disc pl-4 RCaption12">
                            <li>인쇄가 시작된 이후에는 결제 취소가 불가능합니다. 결제 취소를 원하신다면 mm월 dd일까지 환불 신청 부탁드립니다.</li>
                            <li>환불 신청은 고객센터로 문의 부탁드립니다.</li>
                        </ol>
                    </div>
                    <div>
                        <div className="MCaption12">기타안내</div>
                        <ol className="list-disc pl-4 RCaption12">
                            <li>기타 문의 사항은 고객센터로 1:1 문의 부탁드립니다.</li>
                        </ol>
                    </div>
                </div>
            </div>
            <div className="fixed bottom-0 px-5 max-w-[400px] bg-opacity-100 pb-8 z-50 left-2/4 w-full -translate-x-2/4">
                <div className='absolute -top-8 right-5 BBody16 animate-bounce'>👇클릭!👇</div>
                <FillButton
                    onClick={() => {
                        window.open("https://naver.me/xs32NRR0", "_blank", "noopener, noreferrer");
                    }}
                    variant="Primary"
                    size={"B"}
                    text="신청 폼 제출하기"
                />
            </div>
        </div>
    );
};

export default DeliveryComplete;