import React, { useEffect } from "react";

import { Route, Routes, useLocation } from "react-router-dom";
import Main from "./pages/Main";
import Layout from "./components/Layout";
import Login from "pages/Login";
import Signup from "pages/Signup";

import ArtistList from "pages/ArtistList";
import StaffList from "pages/StaffList";
import StaffDetail from "pages/StaffDetail";
import ArtistDetail from "pages/ArtistDetail/ArtistDetail"
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import CastingList from "pages/CastingList";
import CastingDetail from "pages/CastingDetail";
import StaffProductDetail from "pages/StaffProductDetail";
import CreateArtistProfile from "pages/CreateArtistProfile";
import CreateStaffProfile from "pages/CreateStaffProfile";

import StaffMyProfile from "pages/StaffMyProfile";
import ArtistMy from "pages/ArtistDetail/ArtistMy";
import More from "pages/More/More";
import CreateCastingCall from "pages/CastingCall/CreateCastingCall";
import CreateProduction from "pages/CreateProduction";
import StaffMyCasting from "pages/StaffMyCasting";
import PointDetails from "pages/PointDetails";
import CacheCharge from "pages/PointCharge";
import ReceivedProfile from "pages/Received/ReceivedProfile";
import ArtistReceiveSuggest from "pages/ArtistReceiveSuggest";
import UpdateArtistProfile from "pages/UpdateArtistProfile";
import ArtistSendProfile from "pages/ArtistSendProfile";
import StaffMyProductDetail from "pages/StaffMyProductDetail";
import MyCasting from "pages/MyCasting";
import ArtistShortlist from "pages/ArtistDetail/ArtistShortlist";
import KmcCallback from "pages/KmcCallback";
import UpdateStaffProfile from "pages/UpdateStaffProfile";
import UpdateProduction from "pages/UpdateProduction";
import Terms from "pages/Terms";
import Private from "pages/Private";
import ThirdPartySharing from "pages/ThirdPartySharing";
import MarketingOptIn from "pages/MarketingOptIn";
import CastingReference from "pages/Casting/CastingReference";
import ArtistShared from "pages/ArtistShared";
import Membership from "pages/Membership";
import MyPage from "pages/MyPage";
import MembershipSubscribe from "pages/MembershipSubscribe";
import MembershipCancel from "pages/MembershipCancel";
import MembershipPaymentMethod from "pages/MembershipPaymentMethod";
import ArtistPick from "pages/ArtistPick";
import NotFound from "pages/NotFound";
import MemberFindPassword from "pages/MemberFindPassword";
import MemberVerify from "pages/MemberVerify";
import MemberFindId from "pages/MemberFindId";
import MembershipMy from "pages/MembershipMy";
import MembershipHistroy from "pages/MembershipHistroy";
import PrivatePayment from "pages/PrivatePayment";
import MemberWithdrawal from "pages/MemberWithdrawal ";
import StaffSendSuggest from "pages/StaffSendSuggest";
import WelcomePayClose from "pages/WelcomepayClose";
import PointChargingHistory from "pages/PointChargingHistory";
import PointUsageHistory from "pages/PointUsageHistory";
import PointChargeDetail from "components/PointChargeDetail";
import KakaoLoginSuccess from "./pages/KakaoLoginSuccess";
import KakaoLoginFail from "./pages/KakaoLoginFail";
import KakaoSignup from "./pages/KakaoSignup";
import KakaoAccountLink from "pages/KakaoAccountLink";
import ManageArtist from "pages/ManageArtist";
import ManageArtistProfile from "pages/ManageArtistProfile";
import ManageArtistDetail from "pages/ManageArtistDetail";
import ManageArtistProfileUpdate from "pages/ManageArtistProfileUpdate";
import UpdateCastingCall from "pages/CastingCall/UpdateCastingCall";
import StaffFeedbackState from "pages/StaffFeedbackState/StaffFeedbackState";
import StaffMyCastingcall from "pages/StaffMyCastingcall";
import useLoginInfo from "stores/loginInfo";
import Banners from "pages/Banner/Banners";
import SuggestMyProducts from "pages/Suggest/SuggestMyProducts";
import SuggestMyCastingcall from "pages/Suggest/SuggestMyCastingcall";
import SuggestDescription from "pages/Suggest/SuggestDescription";
import SuggestDeadline from "pages/Suggest/SuggestDeadline";
import ShortlistSelectProduct from "pages/Received/ShortlistSelectProduct"
import ShortlistSelectCastingcall from "pages/Received/ShortlistSelectCastingcall"
import ArtistReceived from "pages/ArtistDetail/ArtistReceived";
import CastingReferenceProcess from "pages/Casting/CastingReferenceProcess";
import CastingProcess from "pages/Casting/CastingProcess";
import ArtistDelivery from "pages/ArtistDetail/ArtistDelivery";
import ArtistApplied from "pages/ArtistDetail/ArtistApplied";
import Scrap from "pages/Scrap/Scrap";
import DeliveryComplete from "pages/ProfileDelivery/DeliveryComplete";
import ManagerSendProfile from "pages/ManagerSendProfile";
import DeliverySubscribe from "pages/ProfileDelivery/DeliverySubscribe";
import DeliveryHistory from "pages/ProfileDelivery/DeliveryHistory";

function App() {
  const location = useLocation();
  const { setLoginInfo, isLoading, isLogin } = useLoginInfo();
  const login = location.search !== "?code=4001" && isLogin === true;

  useEffect(() => {
    if (login) {
      setLoginInfo();
    }
  }, []);

  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  window.addEventListener("resize", () => setScreenSize());

  if (isLogin && isLoading) {
    return <div />;
  }

  return (
    <Routes>
      <Route path="/*" element={<NotFound />} />
      <Route element={<Layout />}>
        <Route path="/" element={<Main />} />
        <Route path="/banner" element={<Banners />} />
        <Route path="/scrap" element={<Scrap />} />

        <Route path="/casting" element={<CastingList />} />
        <Route path="/artist" element={<ArtistList />} />
        <Route path="/staff" element={<StaffList />} />

        <Route path="/more" element={<More />} />
        <Route path="/login" element={<Login />} />

        <Route path="/mypage" element={<MyPage />} />

        <Route path="/terms" element={<Terms />} />
        <Route path="/private" element={<Private />} />
        <Route path="/third-party-sharing" element={<ThirdPartySharing />} />
        <Route path="/marketing-optin" element={<MarketingOptIn />} />
        <Route path="/private-pay" element={<PrivatePayment />} />

        <Route path="/staff/my" element={<StaffMyProfile />} />
        <Route path="/staff/profile/edit" element={<UpdateStaffProfile />} />


        <Route path="/point" element={<PointDetails />} />
        <Route path="/point/charge" element={<CacheCharge />} />
        <Route
          path={`/point/charge/:paymentId`}
          element={<PointChargeDetail />}
        />

        <Route
          path="/point/history/charge"
          element={<PointChargingHistory />}
        />
        <Route path="/point/history/use" element={<PointUsageHistory />} />
        <Route path="/welcomepay/close" element={<WelcomePayClose />} />


        <Route path="/withdrawal" element={<MemberWithdrawal />} />


        <Route path="/profile-delivery" element={<DeliverySubscribe />} />
        <Route path="/delivery/history" element={<DeliveryHistory />} />
        <Route path="/delivery/complete" element={<DeliveryComplete />} />


        <Route path="/membership" element={<Membership />} />

        <Route path="/membership/subscribe" element={<MembershipSubscribe />} />
        <Route path="/membership/cancel" element={<MembershipCancel />} />
        <Route path="/membership/history" element={<MembershipHistroy />} />
        <Route path="/membership/my" element={<MembershipMy />} />
        <Route
          path="/membership/payment-method"
          element={<MembershipPaymentMethod />}
        />
        <Route path="/link/kakao" element={<KakaoAccountLink />} />
        <Route path="/find/verify" element={<MemberVerify />} />
        <Route path="/find/password" element={<MemberFindPassword />} />
        <Route path="/find/id" element={<MemberFindId />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/kmc/callback" element={<KmcCallback />} />


        <Route path="/artist/pick" element={<ArtistPick />} />
        <Route path="/artist/:artistId" element={<ArtistDetail />} />
        <Route path="/artist/profile" element={<CreateArtistProfile />} />
        <Route path="/artist/profile/edit" element={<UpdateArtistProfile />} />
        <Route path="/artist/my" element={<ArtistMy />} />
        <Route path="/artist/suggest" element={<ArtistReceiveSuggest />} />
        <Route path="/artist/send" element={<ArtistSendProfile />} />
        <Route path="/artist/share" element={<ArtistShared />} />
        <Route path="/artist/qrcode/:id" element={<ArtistDelivery />} />

        <Route path="/suggest/product" element={<SuggestMyProducts />} />
        <Route path="/suggest/castingcall" element={<SuggestMyCastingcall />} />
        <Route path="/suggest/description" element={<SuggestDescription />} />
        <Route path="/suggest/deadline" element={<SuggestDeadline />} />

        <Route path="/staff/suggest" element={<StaffSendSuggest />} />
        {/* 받은 프로필 */}
        <Route path="/staff/received/:artistId" element={<ArtistReceived />} />
        {/* 내 캐스팅 - 후보, 리스트업, 캐스팅 확정 */}
        <Route path="/staff/shortlist/:artistId" element={<ArtistShortlist />} />
        {/* 내 캐스팅 - 지원 */}
        <Route path="/staff/applied/:artistId" element={<ArtistApplied />} />
        <Route path="/staff/:staffId" element={<StaffDetail />} />
        <Route path="/staff/profile" element={<CreateStaffProfile />} />
        <Route path="/staff/reference" element={<CastingReference />} />
        <Route path="/staff/casting/my" element={<StaffMyCasting />} />
        <Route path="/staff/castingcall/:id" element={<StaffMyCastingcall />} />
        {/* <Route path="/staff/casting/process/:id" element={<CastingProcess />} /> */}
        <Route path="/staff/casting/process/:id" element={<CastingProcess />} />
        <Route path="/staff/casting/ref/process/:id" element={<CastingReferenceProcess />} />
        <Route path="/staff/post" element={<CreateProduction />} />
        <Route path="/staff/post/edit/:id" element={<UpdateProduction />} />
        <Route path="/staff/casting-call" element={<CreateCastingCall />} />
        <Route
          path="/staff/casting-call/edit/:id"
          element={<UpdateCastingCall />}
        />
        <Route path="/staff/product/:staffId" element={<StaffProductDetail />} />
        <Route path="/staff/product/my/:productId" element={<StaffMyProductDetail />} />
        <Route path="/staff/feedback" element={<StaffFeedbackState />} />

        <Route path="/staff/received" element={<ReceivedProfile />} />
        <Route path="/staff/shortlist/product" element={<ShortlistSelectProduct />} />
        <Route path="/staff/shortlist/castingcall" element={<ShortlistSelectCastingcall />} />

        <Route path="/casting/:itemId" element={<CastingDetail />} />
        <Route path="/casting/my/:itemId" element={<MyCasting />} />

        <Route path="/oauth/kakao/success" element={<KakaoLoginSuccess />} />
        <Route path="/oauth/kakao/fail" element={<KakaoLoginFail />} />
        <Route path="/signup/kakao" element={<KakaoSignup />} />

        <Route path="/manage/artist" element={<ManageArtist />} />
        <Route
          path="/manage/artist/profile"
          element={<ManageArtistProfile />}
        />
        <Route
          path="/manage/artist/detail/:artistId"
          element={<ManageArtistDetail />}
        />
        <Route
          path="/manage/artist/:artistId/edit"
          element={<ManageArtistProfileUpdate />}
        />
        <Route
          path="/manage/artist/send"
          element={<ManagerSendProfile />}
        />
      </Route>
    </Routes>

  );
}

export default App;
