import React from "react";

import { ReactComponent as BookmarkOff } from "assets/bookmark_off_list.svg";
import { ReactComponent as BookmarkOn } from "assets/bookmark_on.svg";
import { useDeleteScrapCastingcall, useScrapCastingcall } from "apis/scrap";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router";

interface NoticeCardType {
  src?: string;
  onClick?: () => void;
  type?: string;
  productionTitle: string;
  title: string;
  gender: string;
  age: string;
  deadline?: string;
  castingCallId: number;
  favoriteCastingCallId: number | null;
  isBookmark?: boolean;
  deadlineSoon?: boolean;
}

const NoticeCard = ({
  type,
  productionTitle,
  title,
  gender,
  age,
  deadline,
  castingCallId,
  favoriteCastingCallId,
  isBookmark,
  deadlineSoon,
  onClick,
}: NoticeCardType) => {
  const location = useLocation();



  const queryClient = useQueryClient();

  const { mutate: add } = useScrapCastingcall();
  const { mutate: remove } = useDeleteScrapCastingcall();

  const handleBookmark = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (isBookmark) {
      remove(favoriteCastingCallId, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["castingcalls"] });
          queryClient.invalidateQueries({ queryKey: ["favoriteCastingcall"] });
        },
      });
    } else {
      add(castingCallId, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["castingcalls"] });
          queryClient.invalidateQueries({ queryKey: ["favoriteCastingcall"] });
        },
      });
    }
  };

  return (
    <div
      onClick={onClick}
      className="w-full relative p-4 cursor-pointer border border-Gray03 rounded-[10px] overflow-hidden"
    >
      <div onClick={handleBookmark} className="absolute right-4 top-4">
        {isBookmark ? <BookmarkOn /> : <BookmarkOff />}
      </div>
      <div className="flex items-center justify-between">
        <div className={`flex flex-wrap ${type && "gap-2"}`}>
          <div className="MBody14 text-Gray05">{type ? type : "작품"}</div>
          <div className="MBody14 text-BlueReadyAction">{productionTitle}</div>
        </div>
      </div>
      <div className="mb-2 BBody18 overflow-hidden text-ellipsis whitespace-nowrap max-w-[328px]">
        {title}
      </div>
      <div className="mb-1 MBody14 gap-2 flex items-center">
        <div>{gender}</div>
        <div>|</div>
        <div>{age}</div>
      </div>
      <div className="flex items-center justify-between">
        <div className="MBody14 text-Gray05">{deadline}</div>
        {deadlineSoon && (
          <div className="bg-[#FFEFEF] MCaption10 text-[#FF6060] px-2 py-1 rounded-[5px]">
            마감임박
          </div>
        )}
      </div>
    </div>
  );
};

export default NoticeCard;
