import React, { useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Toast } from "hooks/useToast";
import { HAS_PROFILE, MY_ROLE } from "utils/onRoleStatus";
import StaffDescCard from "./StaffDetail/_components/StaffDescCard";
import StaffArtistsProfiles from "./StaffDetail/_components/StaffArtistsProfiles";
import StaffProductions from "./StaffDetail/_components/StaffProductions";
import useLoginInfo from "stores/loginInfo";

import HeaderWithBackButton from "components/HeaderWithBackButton";
import Textarea from "components/Textarea";
import ScrollToTop from "components/ScrollToTop";
import ButtonBackground from "components/ButtonBackground";
import { FillButton } from "components/FillButton";
import FullPageModal from "components/Modal/FullPageModal";

import { ReactComponent as ProfileSend } from 'assets/profileSend.svg'

import { useStaffProfile } from "apis/profile";
import { usePointCheckByRole } from "apis/point";
import { useDeliveryProfileToStaff } from "apis/deliveryProfile";

export interface Profile {
  birthDate?: string;
  profileId: number;
  profileName: string;
  thumbnailUrl: string;
}

const StaffDetail = () => {
  const { loginInfo } = useLoginInfo();

  const [searchParams, setSearchParams] = useSearchParams();

  const myRole = MY_ROLE();
  const hasProfile = HAS_PROFILE();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { staffId } = useParams();

  const [selectedProfiles, setSelectedProfiles] = useState<Profile[]>([]);

  const [sendProfileData, setSendProfileData] = useState<{
    staffId: number | null;
    profileIds: number[] | null;
    description?: string;
    type: string;
  }>({
    staffId: null,
    profileIds: null,
    description: "",
    type: "",
  });

  const { data } = useStaffProfile(Number(staffId));

  const staffData = data?.data;

  const mutate = useDeliveryProfileToStaff();

  const { data: rolePointData } = usePointCheckByRole({
    role: staffData?.role.code!,
    type: sendProfileData.type!
  });

  const rolePoint = rolePointData?.data;

  const usePoint = selectedProfiles.length * (rolePoint?.point || 0);

  const sendProfileToStaff = () => {
    mutate.mutate(sendProfileData,
      {
        onSuccess: () => {
          Toast.success(`${staffData?.profileName}님께 프로필을 보냈어요.`);
          setSendProfileData({ ...sendProfileData, description: "" })
          setSelectedProfiles([])
          navigate(-1);
        }
      }
    );
  };

  const managerArtistSelectData = {
    staffId: staffData?.memberId,
    staffName: staffData?.profileName,
    staffRoleType: staffData?.role?.code,
    staffRole: staffData?.role?.label
  }

  const isPending = staffData?.status?.code === "PENDING";
  const staffRole = staffData?.role?.code;

  return (
    <>
      <HeaderWithBackButton title={""}>

      </HeaderWithBackButton>
      <div className="pt-5 pb-24 flex flex-col">
        <ScrollToTop />
        <div className="px-5">

          <StaffDescCard
            staffName={staffData?.profileName!}
            imageUrl={staffData?.thumbnailUrl!}
            staffRole={staffData?.role.label!}
            staffAgency={staffData?.agency!}
            filmography={staffData?.filmographies!}
            filmographySummary={staffData?.filmographySummary!}
          />
          <div>
            {myRole === "ROLE_ARTIST" || (myRole === "ROLE_MANAGER" && staffData?.role?.code !== "MANAGER")
              ? (
                <>
                  <div className="flex items-center mt-4 gap-3">
                    <FillButton
                      disabled={isPending}
                      onClick={() => {
                        setSendProfileData({
                          ...sendProfileData,
                          type: "DELIVERY",
                          staffId: staffData?.memberId!,
                          profileIds: [loginInfo?.profileId!]
                        });
                        if (myRole === "ROLE_MANAGER") {
                          navigate("/manage/artist/send", { state: managerArtistSelectData });
                        } else {
                          if (!hasProfile) {
                            navigate("/artist/my");
                          } else {
                            setSearchParams({ "staff-profile-send": "true" });
                          }
                        }
                      }}
                      className="w-full"
                      text="프로필 보내기"
                      variant="Membership"
                      size="M"
                    />
                  </div>
                  {isPending && (
                    <div className="text-Gray05 mt-4 MCaption12 text-center">
                      승인되지 않은 사용자에게 프로필을 보낼 수 없어요.
                    </div>
                  )}
                </>
              ) : ""}
          </div>
        </div>
        <div className="pt-4">
          {staffRole === "MANAGER" ? (
            <StaffArtistsProfiles
              isManager={state?.role === "MANAGER"}
              memberId={staffData?.memberId!}
            />
          ) : (
            <StaffProductions memberId={staffData?.memberId!} />
          )}
        </div>
      </div>
      {searchParams.get("staff-profile-send") &&
        <FullPageModal onClick={() => {
          navigate(-1)
        }}>
          <div className="px-5 pt-10 pb-40">
            <div className="BBody20 flex flex-col gap-7 mx-auto">
              <div>
                <div className="BBody20 text-center">{staffData?.role?.label} {staffData?.profileName}님에게<br />
                  프로필을 보낼까요?
                </div>
                {selectedProfiles.length > 0 &&
                  <div className="mt-2 MBody16 text-Gray05 flex justify-center">
                    {selectedProfiles.map((item) => item.profileName).join(", ")}
                  </div>
                }
              </div>
              <ProfileSend className="mx-auto" />
              <Textarea
                placeholder="프로필과 함께 보낼 내용을 자유롭게 적어주세요."
                onChange={(e) =>
                  setSendProfileData({
                    ...sendProfileData,
                    description: e.target.value,
                  })
                }
                rows={7}
                value={sendProfileData.description}
                maxlength={500}
              />
            </div>
            <div className="mt-10 text-Gray05 flex flex-col gap-4">
              <div className="BCaption14">프로필을 보내기 전에 확인해 주세요.</div>
              <ol className="list-disc pl-4 RCaption14 space-y-2">
                <li>프로필에 연기영상/자유영상을 첨부하면 캐스팅 확률이 높아져요.</li>
                <li>프로필은 보낸 시점의 프로필이 보여져요. 업데이트된 프로필을 보내려면 수정 후 다시 보내주세요.</li>
                <li>프로필 받은 스탭 회원은 내 연락처를 열람할 수 있어요.</li>
                <li>
                  보낸 프로필로 사용한 포인트는 30일 이후에도 스탭 회원의 피드백이 없는경우, 영업일 기준 2-3일 이내 반환됩니다.
                </li>
              </ol>
            </div>
          </div>
          <ButtonBackground>
            <FillButton
              variant="Membership"
              size="B"
              isLoading={mutate.isPending}
              onClick={() => {
                sendProfileToStaff();
              }}
              text={`${myRole === "ROLE_MANAGER" ? `${usePoint.toLocaleString()}포인트로 프로필 보내기` : `${rolePoint?.point.toLocaleString()}포인트로 프로필 보내기`}`}
            />
          </ButtonBackground>
        </FullPageModal>
      }
    </>
  );
};

export default StaffDetail;