import React, { ReactNode } from "react";

import { Slide, toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const defaultToastOption: ToastOptions = {
  position: "bottom-center",
  autoClose: 500,
  hideProgressBar: true,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: false,
  draggable: true,
  pauseOnHover: false,
  closeButton: false,
  transition: Slide,
};

export const Toast = {
  info: (message: ReactNode, options: ToastOptions = {}) => {
    toast.info(message, { ...defaultToastOption, ...options });
  },
  success: (message: ReactNode, options: ToastOptions = {}) => {
    toast.success(message, { ...defaultToastOption, ...options });
  },
  error: (message: ReactNode, options: ToastOptions = {}) => {
    toast.error(message, { ...defaultToastOption, ...options });
  },
  warning: (message: ReactNode, options: ToastOptions = {}) => {
    toast.warning(message, { ...defaultToastOption, ...options });
  },
};
