import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import requestToCastingVote from "./api";
import {
  changeCastingcallStatusRequest,
  castingcallsRequest,
  castingcallsByProductionRequest,
} from "types/castingcall";
import { createCasting, updateCasting } from "types/casting/casting";

// 공고 등록
const createCastingcall = async (createCastingcallRequest: createCasting) => {
  return await requestToCastingVote
    .post(`castingcalls`, createCastingcallRequest)
    .then((res) => res.data);
};

const useCreateCastingcall = () => {
  return useMutation({
    mutationFn: createCastingcall,
  });
};

// 공고 수정
const updateCastingcall = async (updateCastingcallRequest: updateCasting) => {
  return await requestToCastingVote
    .patch(`castingcalls`, updateCastingcallRequest)
    .then((res) => res.data);
};

const useUpdateCastingcall = () => {
  return useMutation({
    mutationFn: updateCastingcall,
  });
};

// 공고 상태 변경
const changeCastingcallStatus = async (
  changeCastingcallStatusRequest: changeCastingcallStatusRequest
) => {
  return await requestToCastingVote
    .patch(`castingcalls/status`, changeCastingcallStatusRequest)
    .then((res) => res.data);
};

const useChangeCastingcallStatus = () => {
  return useMutation({
    mutationFn: changeCastingcallStatus,
  });
};

// 공고 삭제
const deleteCastingcall = async (castingCallId: number | null) => {
  return await requestToCastingVote
    .delete(`castingcalls`, { data: { castingCallId } })
    .then((res) => res.data);
};

const useDeleteCastingcall = () => {
  return useMutation({
    mutationFn: deleteCastingcall,
  });
};

// 공고 목록 조회
const getCastingcalls = async (castingcallsRequest: castingcallsRequest) => {
  return await requestToCastingVote
    .get(`castingcalls`, { params: castingcallsRequest })
    .then((res) => res.data);
};

const useCastingcalls = (castingcallsRequest: castingcallsRequest) => {
  return useInfiniteQuery({
    queryKey: ["castingcalls", castingcallsRequest],
    queryFn: ({ pageParam = 0 }) =>
      getCastingcalls({ ...castingcallsRequest, page: pageParam }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

// 공고 상세 조회
const getCastingcall = async (id: number): Promise<any> => {
  return await requestToCastingVote
    .get(`castingcalls/${id}`)
    .then((res) => res.data);
};

const useCastingcall = (id: number) => {
  return useQuery({
    queryKey: ["castingcall", id],
    queryFn: () => getCastingcall(id),
  });
};

// 작품별 공고 목록 조회
const getCastingcallsByProduction = async (
  castingcallsByProductionRequest: castingcallsByProductionRequest
) => {
  return await requestToCastingVote
    .get(`productions/${castingcallsByProductionRequest.id}/castingcalls`, {
      params: castingcallsByProductionRequest,
    })
    .then((res) => res.data);
};

const useCastingcallsByProduction = (
  castingcallsByProductionRequest: castingcallsByProductionRequest
) => {
  return useInfiniteQuery({
    queryKey: ["castingcallsByProduction", castingcallsByProductionRequest],
    queryFn: ({ pageParam = 0 }) =>
      getCastingcallsByProduction({
        ...castingcallsByProductionRequest,
        page: pageParam,
      }),
    enabled: !!castingcallsByProductionRequest.id,
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

export {
  useCreateCastingcall,
  useUpdateCastingcall,
  useChangeCastingcallStatus,
  useDeleteCastingcall,
  useCastingcalls,
  useCastingcall,
  useCastingcallsByProduction,
};
