import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLocation, useNavigate } from 'react-router';

import { useArtistProfilesByManager } from 'apis/profile';
import useDebounce from 'hooks/useDebounce';
import HeaderWithBackButton from 'components/HeaderWithBackButton';

import { ReactComponent as Search } from "assets/icon_search.svg";
import { ReactComponent as ProfileSend } from 'assets/profileSend.svg'

import MemberSelectCard from 'components/MemberSelectCard';
import { Profile } from './StaffDetail';
import { splitBirth } from 'utils/onBirth';
import { useDeliveryProfileToStaff } from 'apis/deliveryProfile';
import { Toast } from 'hooks/useToast';
import ButtonBackground from 'components/ButtonBackground';
import { FillButton } from 'components/FillButton';
import FullPageModal from 'components/Modal/FullPageModal';
import Textarea from 'components/Textarea';
import { usePointCheckByRole, usePointCheck } from 'apis/point';


const ManagerSendProfile = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const [descModal, setDescModal] = useState(false);
    const [selectedProfiles, setSelectedProfiles] = useState<Profile[]>([]);
    const [desc, setDesc] = useState<string>("")
    const [searchValue, setSearchValue] = useState<string>("");
    const debouncedSearchText = useDebounce(searchValue, 200);

    const {
        data,
        fetchNextPage,
        hasNextPage,
    } = useArtistProfilesByManager({ name: debouncedSearchText });

    const { ref, inView } = useInView({
        threshold: 0.5,
    });

    useEffect(() => {
        if (inView) {
            fetchNextPage();
        }
    }, [inView]);

    const allLists = useMemo(
        () =>
            data ? data?.pages?.flatMap((data) => data.data.content) : [],
        [data]
    );

    const { data: rolePointData } = usePointCheckByRole({
        role: state?.staffRoleType,
        type: "DELIVERY"
    });

    const rolePoint = rolePointData?.data;

    const usePoint = selectedProfiles.length * (rolePoint?.point || 0);

    const {
        isError,
    } = usePointCheck(usePoint);

    const handleSelect = useCallback(
        (profile: Profile) => {
            setSelectedProfiles((prev = []) => {
                const isSelected = prev.some((p) => p.profileId === profile.profileId);

                if (isSelected) {
                    return prev.filter((p) => p.profileId !== profile.profileId);
                }

                if (isError) return prev;

                return [...prev, profile];
            });
        },
        [setSelectedProfiles, isError]
    );


    const mutate = useDeliveryProfileToStaff();

    const sendProfile = () => {
        mutate.mutate({
            staffId: state?.staffId,
            profileIds: selectedProfiles?.map((item) => item.profileId),
            description: "",
            type: "DELIVERY"
        },
            {
                onSuccess: () => {
                    Toast.success(`${state?.staffName}님께 프로필을 보냈어요.`);
                    setSelectedProfiles([])
                    navigate(-1);
                }
            })
    }

    return (
        <div className='pb-24'>
            <HeaderWithBackButton title={''} />
            <div className='BBody20 text-Gray09 px-5 py-1'>
                어떤 프로필을 보낼까요?
            </div>
            <div className='px-5'>
                <div className="relative MBody14 mt-7 mb-4">
                    <input
                        value={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}
                        placeholder={"이름 입력"}
                        className="w-full border py-3 pr-[44px] rounded-md px-[16px] RBody16"
                    />
                    <Search className="absolute top-[15px] right-4" />
                </div>
            </div>
            {allLists.length === 0 ? (
                <>
                    <div className="mt-16 items-center gap-2 flex flex-col justify-center text-center">
                        <div className="text-Gray05 RBody14">프로필이 없어요</div>
                        <FillButton
                            onClick={() => navigate("/manage/artist/profile")}
                            size="L"
                            variant="Membership"
                            text="아티스트 등록"
                        />
                    </div>
                </>
            ) : (
                <div>
                    {allLists?.map((item) => (
                        <MemberSelectCard
                            key={item.profileId}
                            onClick={() => handleSelect(item)}
                            imageUrl={item.thumbnailUrl}
                            title={item.profileName}
                            subTitle={splitBirth(item.birthDate ?? "")}
                            selected={!!selectedProfiles?.some((p) => p.profileId === item.profileId)}
                        />
                    ))}
                    {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
                </div>
            )}
            {selectedProfiles?.length! > 0 &&
                <ButtonBackground>
                    <FillButton
                        disabled={isError}
                        onClick={() => {
                            setDescModal(true)
                        }}
                        size="B"
                        variant="Membership"
                        text={isError ? "포인트가 부족해요" : `프로필 ${selectedProfiles.length}개 보내기`}
                        className="w-full"
                    />
                </ButtonBackground>
            }

            {descModal &&
                <FullPageModal onClick={() => {
                    navigate(-1)
                }}>
                    <div className="px-5 pt-10 pb-40">
                        <div className="BBody20 flex flex-col gap-7 mx-auto">
                            <div>
                                <div className="BBody20 text-center">{state?.staffRole} {state?.staffName}님에게<br />
                                    프로필을 보낼까요?
                                </div>
                                {selectedProfiles.length > 0 &&
                                    <div className="mt-2 MBody16 text-Gray05 flex justify-center">
                                        {selectedProfiles.map((item) => item.profileName).join(", ")}
                                    </div>
                                }
                            </div>
                            <ProfileSend className="mx-auto" />
                            <Textarea
                                placeholder="프로필과 함께 보낼 내용을 자유롭게 적어주세요."
                                onChange={(e) =>
                                    setDesc(e.target.value)
                                }
                                rows={7}
                                value={desc}
                                maxlength={500}
                            />
                        </div>
                        <div className="mt-10 text-Gray05 flex flex-col gap-4">
                            <div className="BCaption14">프로필을 보내기 전에 확인해 주세요.</div>
                            <ol className="list-disc pl-4 RCaption14 space-y-2">
                                <li>프로필에 연기영상/자유영상을 첨부하면 캐스팅 확률이 높아져요.</li>
                                <li>프로필은 보낸 시점의 프로필이 보여져요. 업데이트된 프로필을 보내려면 수정 후 다시 보내주세요.</li>
                                <li>프로필 받은 스탭 회원은 내 연락처를 열람할 수 있어요.</li>
                                <li>
                                    보낸 프로필로 사용한 포인트는 30일 이후에도 스탭 회원의 피드백이 없는경우, 영업일 기준 2-3일 이내 반환됩니다.
                                </li>
                            </ol>
                        </div>
                    </div>
                    <ButtonBackground>
                        <FillButton
                            variant="Membership"
                            size="B"
                            isLoading={mutate.isPending}
                            onClick={() => {
                                sendProfile();
                            }}
                            text={`${usePoint.toLocaleString()} 포인트로 프로필 보내기`}
                        />
                    </ButtonBackground>
                </FullPageModal>
            }
        </div>
    );
};

export default ManagerSendProfile;