import React from "react";

import { ReactComponent as BookmarkOff } from "assets/bookmark_off_list.svg";
import { ReactComponent as BookmarkOn } from "assets/bookmark_on.svg";
import { useScrapProfile, useDeleteScrapProfile } from "apis/scrap";
import { useQueryClient } from "@tanstack/react-query";

interface StaffCardType {
  onClick?: () => void;
  image?: string;
  name?: string;
  gender?: string;
  role?: string;
  isPending?: boolean;
  agency?: string;
  isCasting?: boolean;

  profileId?: number;
  favoriteId?: number | null;
  isBookmark?: boolean;
  Bookmark?: boolean;
}

const StaffCard = ({
  onClick,
  image,
  name,
  role,
  agency,
  isCasting,
  isPending,
  isBookmark,
  favoriteId,
  profileId
}: StaffCardType) => {
  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

  const queryClient = useQueryClient();

  const { mutate: add } = useScrapProfile();
  const { mutate: remove } = useDeleteScrapProfile();

  const handleBookmark = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (isBookmark) {
      remove(favoriteId!, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["staffProfiles"] });
          queryClient.invalidateQueries({ queryKey: ["favoriteProfiles"] });
        },
      });
    } else {
      add({ profileId: profileId!, type: "STAFF" }, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["staffProfiles"] });
          queryClient.invalidateQueries({ queryKey: ["favoriteProfiles"] });
        },
      });
    }
  };

  return (
    <div
      onClick={onClick}
      className="cursor-pointer border border-Gray03 gap-4 rounded-md p-4 w-full flex items-center"
    >
      {MEDIA_URL && (
        <img
          src={`${MEDIA_URL}${image}`}
          alt="thumbnail"
          className="w-[60px] h-[60px] object-cover rounded-full"
        />
      )}
      <div className="flex-1">
        <div className="flex items-center justify-between BBody16">
          <div className="flex items-center gap-2">
            <div>{name}</div>
            {isPending && (
              <div className="MCaption12 flex flex-col justify-center text-Gray05 border h-7 border-Gray03 px-2 text-center rounded-full">
                승인대기
              </div>
            )}
            {isCasting && (
              <div className="MCaption12 flex flex-col justify-center text-Blue04 border h-7 border-Blue04 px-2 text-center rounded-full">
                캐스팅중
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center text-Gray05 gap-2 my-2 BBody14">
          <div>{role}</div>
          <div>|</div>
          <div className="flex-1 text-ellipsis overflow-hidden whitespace-nowrap">
            {agency}
          </div>
        </div>
      </div>
      <div onClick={handleBookmark} className="cursor-pointer">
        {isBookmark ? <BookmarkOn /> : <BookmarkOff />}
      </div>
    </div>
  );
};

export default StaffCard;
