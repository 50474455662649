import React from 'react';
import { useNavigate } from 'react-router';
import { MEMBERSHIP_ACTIVE } from 'utils/onRoleStatus';

import { ReactComponent as KakaoBLK } from "assets/kakao_blk.svg";

const Artist = () => {
    const navigate = useNavigate();
    const isActive = MEMBERSHIP_ACTIVE();


    const artist = [
        { id: 0, title: "내 프로필", path: "/artist/my" },
        { id: 1, title: "보낸 프로필", path: "/artist/send" },
        { id: 2, title: "받은 제안", path: "/artist/suggest" },
        { id: 3, title: "스크랩", path: "/scrap" },
    ]

    return (
        <div className='flex flex-col gap-4'>
            <div className='flex flex-col gap-4 px-5'>
                {artist.map((item) => {
                    return (
                        <div
                            onClick={() => {
                                navigate(item.path);
                            }}
                            key={item.id}
                            className='cursor-pointer'>
                            {item.title}
                        </div>
                    )
                })}
            </div>
            <hr className="w-full h-px bg-Gray01" />
            <div className='flex flex-col gap-4 px-5'>

                <div
                    onClick={() => {
                        isActive
                            ? navigate("/membership/my")
                            : navigate("/membership");
                    }}
                    className='cursor-pointer'>
                    멤버십
                </div>
                <div
                    onClick={() => {
                        navigate("/profile-delivery");
                    }}
                    className='cursor-pointer'>
                    딜리버리
                </div>
            </div>
            <hr className="w-full h-px bg-Gray01" />
            <div className="flex MBody18 px-5 flex-col gap-4">
                <div
                    onClick={() => {
                        window.open(
                            "https://daffy-periwinkle-38f.notion.site/1344e358e81b80f9bbd3d7c0a76f3398?pvs=4",
                            "_blank",
                            "noopener, noreferrer"
                        );
                    }}
                    className="cursor-pointer"
                >
                    고객센터
                </div>
            </div>
            <div className="MBody18 px-5">
                <div
                    onClick={() =>
                        window.open(
                            !isActive
                                ? "https://open.kakao.com/o/gA5L052g"
                                : "https://open.kakao.com/o/g2kCD4lh",
                            "_blank",
                            "noopener,noreferrer"
                        )
                    }
                    className="flex items-center gap-1.5 cursor-pointer"
                >
                    <KakaoBLK />
                    <div>{!isActive ? "오픈채팅 바로가기" : "멤버십 회원전용 오픈채팅 바로가기"}</div>
                </div>
            </div>
        </div>
    );
};

export default Artist;